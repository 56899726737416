import React from 'react';

import styles from './Header.module.css';

import Alert from './modules/header-top-alert';
import HeaderTopNavbar from './modules/header-top-nav';

const Header = () => {
  return (
    <header className={styles.header}>
      <Alert />
      <HeaderTopNavbar />
    </header>
  );
};

export default Header;
