import { useDispatch, useSelector } from 'react-redux';
import styles from './CreateCompanyAdsForm.module.scss';
import { bindActionCreators } from 'redux';
import {
  CreateCompanyAds,
  getMyCompaniesForAds,
} from '../../../../../redux/actions/companyAdsActions';
import { getUserInfo } from '../../../../../redux/actions/userActions';
import { useEffect, useState } from 'react';

const CreateCompanyAdsForm = () => {
  const dispatch = useDispatch();
  const actions = {
    getUserInfo: bindActionCreators(getUserInfo, dispatch),
    getMyCompaniesForAds: bindActionCreators(getMyCompaniesForAds, dispatch),
    CreateCompanyAds: bindActionCreators(CreateCompanyAds, dispatch),
  };

  const user = useSelector((state) => state.userReducer);
  const companyAdsReducer = useSelector((state) => state.companyAdsReducer);
  useEffect(() => {
    actions.getUserInfo();
  }, []);

  useEffect(() => {
    if (user.id != null) {
      actions.getMyCompaniesForAds(user.id);
      setAdsData({
        userId: user.id,
        adsDay: 1,
        companyId: 0,
      });
    }
  }, [user.id]);

  const [adsData, setAdsData] = useState();

  useEffect(() => {
    if (user.id) {
      actions.getMyCompaniesForAds(user.id);
      setAdsData({
        userId: user.id,
        adsDay: 1,
        companyId: 0,
      });
    }
  }, [companyAdsReducer?.create]);

  return (
    <>
      {companyAdsReducer.companiesForCreate?.length == 0 ? (
        <div className={styles['empty-text']}>Reklam oluşturmak için uygun firma bulunamadı</div>
      ) : (
        <form onSubmit={(e) => e.preventDefault()} className={styles['form']}>
          <p className={styles['form__success']}>
            {companyAdsReducer?.create?.data && 'Reklam Oluşturuldu'}
          </p>
          <p className={styles['form__error']}>{companyAdsReducer?.create?.errors}</p>
          <div className={styles['form__group']}>
            <label htmlFor='company' className={styles['form__group__label']}>
              Firma Seç
            </label>
            <select
              id='company'
              value={Number(adsData?.companyId)}
              onChange={(e) => setAdsData({ ...adsData, companyId: e.target.value })}
              className={styles['form__group__input']}>
              <option value={0}>Firma seç</option>
              {companyAdsReducer?.companiesForCreate?.map((company, i) => (
                <option value={company.id} key={i}>
                  {company.name}
                </option>
              ))}
            </select>
          </div>

          <div className={styles['form__group']}>
            <label htmlFor='adsDay' className={styles['form__group__label']}>
              Kaç Gün Reklam Yapılacak ?
            </label>
            <input
              onChange={(e) =>
                e.target.value <= 999999 &&
                setAdsData({ ...adsData, adsDay: Number(e.target.value) || 0 })
              }
              value={Number(adsData?.adsDay)}
              defaultValue={1}
              className={styles['form__group__input']}
              placeholder='ör. 1'
              id='adsDay'
            />
          </div>

          <button onClick={() => actions.CreateCompanyAds(adsData)}>Reklam oluştur</button>
        </form>
      )}
    </>
  );
};

export default CreateCompanyAdsForm;
