import * as types from '../actionTypes/citiesAndTownsTypes';
import { myAxios } from '../myAxios';
import { loadingStateChanged } from './loadingStateActions';

export const citiesAndTownsGetAllSuccess = (data) => ({
  type: types.GET_ALL_CITIES_SUCCESS,
  payload: data,
});

export const citiesAndTownsGetAll = () => {
  return async (dispatch) => {
    try {
      //loading start
      dispatch(loadingStateChanged({ cityList: true }));
      const cityRes = await myAxios().get('api/city');
      const townRes = await myAxios().get('api/town');

      const cities = await cityRes.data;
      const towns = await townRes.data;
      const data = {
        cities: cities.data,
        towns: towns.data,
      };
      //loading end
      dispatch(loadingStateChanged({ cityList: false }));

      return dispatch(citiesAndTownsGetAllSuccess(data.data));
    } catch (ex) {
      //loading end
      dispatch(loadingStateChanged({ cityList: false }));
    }
  };
};
