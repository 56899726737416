import { useDispatch, useSelector } from 'react-redux';
import { getActiveMyCompanyAds } from '../../../../../redux/actions/companyAdsActions';
import { getUserInfo } from '../../../../../redux/actions/userActions';
import styles from './AdsCompanyList.module.scss';
import { bindActionCreators } from 'redux';
import { useEffect, useMemo } from 'react';
import images from '../../../../../images';

const AdsCompanyList = () => {
  const dispatch = useDispatch();
  const actions = {
    getUserInfo: bindActionCreators(getUserInfo, dispatch),
    getActiveMyCompanyAds: bindActionCreators(getActiveMyCompanyAds, dispatch),
  };

  const user = useSelector((state) => state.userReducer);
  const companyAdsReducer = useSelector((state) => state.companyAdsReducer);
  useEffect(() => {
    actions.getUserInfo();
  }, []);

  useEffect(() => {
    if (user.id) {
      actions.getActiveMyCompanyAds(user.id);
    }
  }, [user]);

  useEffect(() => {
    if (user.id) {
      actions.getActiveMyCompanyAds(user.id);
    }
    actions.getUserInfo();
  }, [companyAdsReducer?.create]);
  return (
    <div className={styles['list']}>
      {companyAdsReducer?.ads?.map((data, i) => (
        <div className={styles['list__ads']} key={i}>
          <img src={data.company.image || images.noImage} className={styles['list__ads__image']} />
          <div className={styles['list__ads__details']}>
            <p className={styles['list__ads__details__detail']}>Firma Adı: {data.company.name}</p>
            <p className={styles['list__ads__details__detail']}>
              {`
                Bu Reklam ${new Date(data.ads.endDate).getFullYear()} Yılının ${
                new Date(data.ads.endDate).getMonth() + 1
              }. Ayının ${new Date(data.ads.endDate).getDate()}. Gününde  son bulacaktır.
                `}
            </p>
            <p className={styles['list__ads__details__detail']}>
              Firma Görüntülenme Sayısı: {data.company.views}
            </p>
            <p className={styles['list__ads__details__detail']}>
              Firma Hizmet Bölgesi:{' '}
              {`${data.company.country} / ${data.company.city} / ${data.company.town}`}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AdsCompanyList;
