import { useState } from 'react';
import styles from './MultipleSelect.module.scss';

const MultipleSelect = ({ label, placeholder, data, setSelectedData, selectedData }) => {
  const [listIsShow, setListIsShow] = useState(false);
  const [searchText, setSearchText] = useState('');

  const selectHandler = (selected) => {
    var element = selectedData.filter((x) => x.id == selected.id)[0];
    if (!element) {
      setSelectedData([...selectedData, selected]);
    } else {
      setSelectedData(selectedData.filter((x) => x.id != selected.id));
    }
  };
  return (
    <div className={styles['multiple-select']}>
      <label htmlFor='search'>{label}</label>
      <div className={styles['multiple-select__header']}>
        <input
          id='search'
          onChange={(e) => setSearchText(e.target.value.toLocaleLowerCase())}
          onFocus={() => setListIsShow(true)}
          className={styles['multiple-select__header__search']}
          placeholder={placeholder}
        />
        <div className={styles['multiple-select__header__selecteds']}>
          {selectedData.map((selected, i) => (
            <p key={i} className={styles['multiple-select__header__selecteds__selected']}>
              {selected.value}
            </p>
          ))}
        </div>
      </div>
      {listIsShow && (
        <>
          <div
            onClick={() => setListIsShow(false)}
            className={styles['multiple-select__options__closer']}></div>
          <div className={styles['multiple-select__options']}>
            {data
              .filter((x) => x.value.toLocaleLowerCase().includes(searchText))
              .map((d, i) => (
                <p
                  key={i}
                  onClick={() => selectHandler(d)}
                  style={{
                    backgroundColor: selectedData.filter((x) => x.id == d.id)[0] ? 'black' : '',
                    color: selectedData.filter((x) => x.id == d.id)[0] ? 'white' : '',
                  }}
                  className={styles['multiple-select__options__option']}>
                  {d.value}
                </p>
              ))}
          </div>
        </>
      )}
    </div>
  );
};

export default MultipleSelect;
