import * as types from '../actionTypes/geographyActionTypes';
import * as initialState from '../initialStates';

export default (state = initialState.geographyState, { type, payload }) => {
  switch (type) {
    case types.GET_ALL_COUNTRIES_SUCCESS:
      return { ...state, countries: payload };

    case types.GET_ALL_CITIES_BY_COUNTRY_ID_SUCCESS:
      return { ...state, cities: payload };

    case types.GET_ALL_TOWNS_BY_CITY_ID_SUCCESS:
      return { ...state, towns: payload };
    default:
      return state;
  }
};
