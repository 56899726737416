import loading from './loading.gif';
import trBayrak from './tr-bayrak.png';
import noImage from './no.jpg';
import notification from './notification.svg';
import message from './message.png';

export default {
  loading,
  trBayrak,
  noImage,
  notification,
  message,
};
