import { useState } from 'react';
import styles from './Ads.module.scss';
import AdsCompanyList from './modules/Company/AdsCompanyList';
import CreateCompanyAdsForm from './modules/Company/CreateCompanyAdsForm';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

const AdsPage = () => {
  const [selectedAdsType, setSelectedAdsType] = useState('company');
  const [selectedListType, setSelectedListType] = useState('company');
  const siteSettings = useSelector((state) => state.siteSettingsReducer);

  return (
    <div className={styles['ads-page']}>
      <Helmet>
        <title>
          {'Reklam Oluşturma - ' +
            (siteSettings.title || "Nakliyecim.net | Türkiye'nin En Kolay Nakliye Platformu")}
        </title>
        <meta
          name='description'
          content={
            siteSettings.description ||
            "Nakliyecim.net ile Türkiye'nin her yerine kolayca nakliye hizmeti alın. Binlerce nakliye firması ve ilanı arasından seçim yapın, en uygun fiyatlı nakliye çözümünü bulun."
          }
        />
        <meta
          name='keywords'
          content={
            siteSettings.keywords ||
            'nakliye, nakliye ilanları, nakliye firmaları, nakliyecim.net, eşya taşıma, yük taşıma, parsiyel taşıma, uygun fiyatlı nakliye, Türkiye'
          }
        />
        {/* Open Graph Elements */}
        <meta property='og:locale' content='tr_TR' />
        <meta property='og:type' content='article' />
        <meta
          property='og:title'
          content='Nakliyecim.net ile artık nakliyecileri bulmak o kadar da zor değil. '
        />
        <meta
          property='og:description'
          content='Nakliye Firmaları ve nakliye ilanlarını bulabilirsiniz. Hemen Kayıt olarak sizler de aramıza katıla bilirsiniz.'
        />
        <meta property='og:url' content='https://nakliyecim.net/about' />
        <meta property='og:image' content={siteSettings.logoPath} />
      </Helmet>
      <div className={styles['ads-page__header']}>
        <h3 className={styles['ads-page__header__title']}>Reklam Oluştur</h3>
        <div className={styles['ads-page__header__ads-types']}>
          <button
            data-selected={selectedAdsType == 'company'}
            onClick={() => setSelectedAdsType('company')}
            data-type='company'
            className={styles['ads-page__header__ads-types__select-button']}>
            Firmanı Reklamı (Günlük Ücret: 30 ₺)
          </button>

          {/* <button
            data-selected={selectedAdsType == 'banner'}
            onClick={() => setSelectedAdsType('banner')}
            data-type='banner'
            className={styles['ads-page__header__ads-types__select-button']}>
            Banner Reklam
          </button>

          <button
            data-selected={selectedAdsType == 'popup'}
            onClick={() => setSelectedAdsType('popup')}
            data-type='popup'
            className={styles['ads-page__header__ads-types__select-button']}>
            Popup Reklam
          </button> */}
        </div>
        <section>
          {/* {selectedAdsType == 'popup' && <CreatePopupAdsForm />}
          {selectedAdsType == 'banner' && <CreateBannerAdsForm />} */}
          {selectedAdsType == 'company' && <CreateCompanyAdsForm />}
        </section>
      </div>
      <div className={styles['ads-page__header']}>
        <h3 className={styles['ads-page__header__title']}>Aktif Reklamlarım</h3>
        <div className={styles['ads-page__header__ads-list-types']}>
          <button
            data-selected={selectedListType == 'company'}
            onClick={() => setSelectedListType('company')}
            data-type='company'
            className={styles['ads-page__header__ads-list-types__select-button']}>
            Firma Reklamlarım
          </button>

          {/* <button
            data-selected={selectedListType == 'banner'}
            onClick={() => setSelectedListType('banner')}
            data-type='banner'
            className={styles['ads-page__header__ads-list-types__select-button']}>
            Banner Reklamlarım
          </button>

          <button
            data-selected={selectedListType == 'popup'}
            onClick={() => setSelectedListType('popup')}
            data-type='popup'
            className={styles['ads-page__header__ads-list-types__select-button']}>
            Popup Reklamlarım
          </button> */}
        </div>
        <section>
          {/* {selectedListType == 'popup' && <AdsPopupList />}
          {selectedListType == 'banner' && <AdsBannerList />} */}
          {selectedListType == 'company' && <AdsCompanyList />}
        </section>
      </div>
    </div>
  );
};

export default AdsPage;
