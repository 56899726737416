import * as types from '../actionTypes/paymentTypes';

import { myAxios } from '../myAxios';
import { loadingStateChanged } from './loadingStateActions';

//get all ratings
export const getPaymentdatauccess = (data) => ({
  type: types.GET_PAYMENT_DATA,
  payload: data,
});

//get all ratings
export const getPaymentData = (paymentObj) => {
  return async (dispatch) => {
    dispatch(loadingStateChanged({ paymentDataLoading: true }));
    var response = await myAxios().post('/api/BalanceOrder', paymentObj);
    var data = await response.data;
    dispatch(getPaymentdatauccess(data.data));
    dispatch(loadingStateChanged({ paymentDataLoading: false }));
  };
};
