import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import img from '../../../images/no.jpg';
import styles from './CompanySettings.module.css';

import AdminList from '../../../components/admin-components/AdminList/AdminList';
import AdminListItem from '../../../components/admin-components/AdminListItem/AdminListItem';
import {
  companyConfirmPendingUpdate,
  companyGetAll,
  companyGetById,
  companyGetPendingUpdate,
  companyIsFailed,
  companyIsSuccess,
} from '../../../redux/actions/companyActions';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const CompanySettings = () => {
  const siteSettings = useSelector((state) => state.siteSettingsReducer);
  const dispatch = useDispatch();

  const companyList = useSelector((state) => state.companyReducer) || [];
  const pendingUpdateCompanies = useSelector((state) => state.companiesPendingUpdateReducer) || [];
  const currentCompany = useSelector((state) => state.companyGetByReducer) || {};
  const actions = {
    getAll: bindActionCreators(companyGetAll, dispatch),
    getById: bindActionCreators(companyGetById, dispatch),
    companySuccess: bindActionCreators(companyIsSuccess, dispatch),
    getPendingUpdateCompanies: bindActionCreators(companyGetPendingUpdate, dispatch),
    confirmPendingUpdate: bindActionCreators(companyConfirmPendingUpdate, dispatch),
    companyFailedMessage: bindActionCreators(companyIsFailed, dispatch),
  };

  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    actions.getAll(false);
    actions.getPendingUpdateCompanies();
  }, []);

  const [isActiveDetail, setIsActiveDetail] = useState({ isActive: false });

  const successHandler = (id) => {
    // Onay verilecek
    actions.companySuccess(id);
    setCompanies(id * 100);
  };

  const detailsHandler = (id) => {
    // id ile çekilecek
    actions.getById(id);
    setIsActiveDetail({ isActive: true });
  };

  const confirmUpdateHandler = (id) => {
    // Onay verilecek
    actions.confirmPendingUpdate(id);
    setCompanies(id);
  };
  const [companyFailedMessage, setCompanyFailedMessage] = useState('');
  return (
    <div>
      <Helmet>
        <title>
          {'Firma Ayarları - ' +
            (siteSettings.title || "Nakliyecim.net | Türkiye'nin En Kolay Nakliye Platformu")}
        </title>
        <meta
          name='description'
          content={
            siteSettings.description ||
            "Nakliyecim.net ile Türkiye'nin her yerine kolayca nakliye hizmeti alın. Binlerce nakliye firması ve ilanı arasından seçim yapın, en uygun fiyatlı nakliye çözümünü bulun."
          }
        />
        <meta
          name='keywords'
          content={
            siteSettings.keywords ||
            'nakliye, nakliye ilanları, nakliye firmaları, nakliyecim.net, eşya taşıma, yük taşıma, parsiyel taşıma, uygun fiyatlı nakliye, Türkiye'
          }
        />
        {/* Open Graph Elements */}
        <meta property='og:locale' content='tr_TR' />
        <meta property='og:type' content='article' />
        <meta
          property='og:title'
          content='Nakliyecim.net ile artık nakliyecileri bulmak o kadar da zor değil. '
        />
        <meta
          property='og:description'
          content='Nakliye Firmaları ve nakliye ilanlarını bulabilirsiniz. Hemen Kayıt olarak sizler de aramıza katıla bilirsiniz.'
        />
        <meta property='og:url' content='https://nakliyecim.net/about' />
        <meta property='og:image' content={siteSettings.logoPath} />
      </Helmet>
      {pendingUpdateCompanies.filter((x) => !x.isFailed).length !== 0 && (
        <AdminList
          title='Güncelleme Bekleyenler'
          theads={['#ID', 'Firma Adı', 'Firma Resmi', 'Firma Adresi', 'Firma Onay']}>
          {pendingUpdateCompanies.map((company, i) => (
            <>
              {
                <AdminListItem
                  key={i}
                  items={[
                    company.id,
                    company.name,
                    <img width={100} src={company.image || img} className={styles.image} />,
                    company.address,
                    company.companySuccess ? (
                      <p className='success-company'>onaylı</p>
                    ) : (
                      <>
                        <button
                          className='add-success-btn'
                          onClick={() => confirmUpdateHandler(company.id)}>
                          Onay Ver
                        </button>
                        <span>
                          <input
                            onChange={(e) => setCompanyFailedMessage(e.target.value)}
                            placeholder='Lütfen red mesajı yazın'
                          />
                          <button
                            className='add-success-btn'
                            onClick={() =>
                              actions.companyFailedMessage(
                                company.updatigCompanyId,
                                companyFailedMessage,
                              )
                            }>
                            Onay Verme
                          </button>
                        </span>
                        <button
                          className='company-details-btn'
                          onClick={() => detailsHandler(company.id)}>
                          Detaylar
                        </button>
                      </>
                    ),
                  ]}
                />
              }
            </>
          ))}
        </AdminList>
      )}

      {companyList.filter(
        (company) => company.isSuccess === false && company.isUpdate === false && !company.isFailed,
      ).length !== 0 && (
        <AdminList
          title='Onay Bekleyenler'
          theads={['#ID', 'Firma Adı', 'Firma Resmi', 'Firma Adresi', 'Firma Onay']}>
          {companyList
            .filter(
              (company) =>
                company.isSuccess === false && company.isUpdate === false && !company.isFailed,
            )
            .map((company, i) => (
              <AdminListItem
                key={i}
                items={[
                  company.id,
                  company.name,
                  <img width={100} src={company.image || img} className={styles.image} />,
                  company.address,
                  company.companySuccess ? (
                    <p className='success-company'>onaylı</p>
                  ) : (
                    <>
                      <button
                        className='add-success-btn'
                        onClick={() => successHandler(company.id)}>
                        Onay Ver
                      </button>
                      <span>
                        <input
                          onChange={(e) => setCompanyFailedMessage(e.target.value)}
                          placeholder='Lütfen red mesajı yazın'
                        />
                        <button
                          className='add-success-btn'
                          onClick={() =>
                            actions.companyFailedMessage(company.id, companyFailedMessage)
                          }>
                          Onay Verme
                        </button>
                      </span>
                      <button
                        className='company-details-btn'
                        onClick={() => detailsHandler(company.id)}>
                        Detaylar
                      </button>
                    </>
                  ),
                ]}
              />
            ))}
        </AdminList>
      )}

      {companyList.filter((company) => company.isFailed == true).length !== 0 && (
        <AdminList
          title='Onaylanmayanlar'
          theads={['#ID', 'Firma Adı', 'Firma Resmi', 'Firma Adresi', 'Firma Onay']}>
          {companyList
            .filter((company) => company.isFailed)
            .map((company, i) => (
              <AdminListItem
                key={i}
                items={[
                  company.id,
                  company.name,
                  <img width={100} src={company.image || img} className={styles.image} />,
                  company.address,
                  company.companySuccess ? (
                    <p className='failed-company'>Onaylanmadı: {company.failedMsg}</p>
                  ) : (
                    <>
                      <button
                        className='add-success-btn'
                        onClick={() => successHandler(company.id)}>
                        Onay Ver
                      </button>
                      <button
                        className='company-details-btn'
                        onClick={() => detailsHandler(company.id)}>
                        Detaylar
                      </button>
                    </>
                  ),
                ]}
              />
            ))}
        </AdminList>
      )}

      {companyList && (
        <AdminList
          title='Onaylı Firmalar'
          theads={['#ID', 'Firma Adı', 'Firma Resmi', 'Firma Adresi', 'Firma Onay']}>
          {companyList
            .filter((company) => company.isSuccess == true)
            .map((company, i) => (
              <AdminListItem
                key={i}
                items={[
                  company.id,
                  company.name,
                  <img width={100} src={company.image || img} className={styles.image} />,
                  company.address,
                  <div className='company-success-box'>
                    <p className='success-company'>Onaylı</p>
                    <Link
                      to={'/company/delete/' + company.id}
                      className='company-details-btn'
                      onClick={() => detailsHandler(company.id)}>
                      Firmayı Kaldır
                    </Link>
                    <span>
                      <input
                        onChange={(e) => setCompanyFailedMessage(e.target.value)}
                        placeholder='Lütfen red mesajı yazın'
                      />
                      <button
                        className='add-success-btn'
                        onClick={() =>
                          actions.companyFailedMessage(company.id, companyFailedMessage)
                        }>
                        Onayı Kaldır
                      </button>
                    </span>
                    <button
                      className='company-details-btn'
                      onClick={() => detailsHandler(company.id)}>
                      Detaylar
                    </button>
                  </div>,
                ]}
              />
            ))}
        </AdminList>
      )}

      {isActiveDetail.isActive && (
        <>
          <div
            className='details-popup-after'
            onClick={() => setIsActiveDetail({ isActive: false })}></div>
          <div className='details-popup'>
            <h1 className='details-popup-control'>
              Detaylar <span onClick={() => setIsActiveDetail({ isActive: false })}>X</span>
            </h1>
            <div className='details'>
              {currentCompany && (
                <>
                  <p>
                    Firma ID(Kimliği) ={'>'} {currentCompany.id}
                  </p>
                  <p>
                    Firma adı ={'>'} {currentCompany.name}
                  </p>
                  <p>
                    Firma Açıklaması ={'>'} {currentCompany.description}
                  </p>
                  <p>
                    Firma Yayında mı ? ={'>'} {currentCompany.isSuccess ? 'Evet' : 'Hayır'}
                  </p>
                  <p>
                    Firma Hatalı bilgilere sahip mi ? ={'>'}
                    {currentCompany.isFailed ? 'Evet' : 'Hayır'}
                  </p>
                  <p>
                    Firma Onaylanmama Mesajı ={'>'} {currentCompany.failedMsg}
                  </p>
                  <p>
                    Firmanın Güncellenmesi mi Gerekiyor ? ={'>'}
                    {currentCompany.isUpdate ? 'Evet' : 'Hayır'}
                  </p>
                  <p>
                    Firmanın adresi ={'>'} {currentCompany.address}
                  </p>
                  <p>
                    Firmanın Bulunduğu şehir ={'>'} {currentCompany.city}
                  </p>
                  <p>
                    Firmanın İlçe ={'>'} {currentCompany.town}
                  </p>
                  <p>
                    Firmanın Telefon Numarası ={'>'} {currentCompany.phone}
                  </p>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CompanySettings;
