import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import styles from './MobileNavbar.module.scss';
import Logout from './../logout/Logout';

const MobileNavbar = () => {
  const user = useSelector((x) => x.userReducer);
  const token = localStorage.getItem('token');

  const notReadMessageLength = useSelector((state) => state.notReadMessageLength);

  const [isMobileMenuActive, setIsMobileMenuActive] = useState(false);
  return (
    <div>
      <div className={styles['navbar']}>
        {!token && (
          <>
            <Link className={styles['navbar__link']} to={'/adverts'}>
              ilanlar
            </Link>
            <div
              onClick={(e) => {
                !e.target.hasAttribute('href') && setIsMobileMenuActive(!isMobileMenuActive);
              }}
              className={styles['navbar__nav__link']}>
              <i className='fas fa-bars'></i>
              <nav
                visible-type={isMobileMenuActive ? 'active' : 'pasive'}
                className={styles['navbar__nav__link__menu']}>
                <Link
                  onClick={(e) => {
                    setIsMobileMenuActive(false);
                  }}
                  to='/advert/add'>
                  <i className='fas fa-plus'></i> Yük Oluştur
                </Link>
                <Link
                  onClick={(e) => {
                    setIsMobileMenuActive(false);
                  }}
                  to='/company/add'>
                  <i className='fas fa-plus'></i> Firma Oluşturma Talebi
                </Link>
                <Link
                  onClick={(e) => {
                    setIsMobileMenuActive(false);
                  }}
                  to='/ads'>
                  <i className='fas fa-plus'></i> Reklam Oluştur{' '}
                </Link>
              </nav>
            </div>
            <Link className={styles['navbar__link']} to={'/companies'}>
              Firmalar
            </Link>
          </>
        )}
        {token && (
          <nav className={styles['navbar__nav']}>
            <Link className={styles['navbar__nav__link']} to={'/'}>
              <i className='fas fa-home'></i>
            </Link>

            <Link className={styles['navbar__nav__link']} to={'/adverts'}>
              ilanlar
            </Link>

            <div
              onClick={(e) => {
                !e.target.hasAttribute('href') && setIsMobileMenuActive(!isMobileMenuActive);
              }}
              className={styles['navbar__nav__link']}>
              <i className='fas fa-bars'></i>
              <nav
                visible-type={isMobileMenuActive ? 'active' : 'pasive'}
                className={styles['navbar__nav__link__menu']}>
                {user.isAdmin && (
                  <Link
                    onClick={() => {
                      setIsMobileMenuActive(false);
                    }}
                    to='/admin'>
                    <i className='fas fa-plus'></i> Admin Panel
                  </Link>
                )}

                <Link
                  onClick={(e) => {
                    setIsMobileMenuActive(false);
                  }}
                  to='/advert/add'>
                  <i className='fas fa-plus'></i> Yük Oluştur
                </Link>
                <Link
                  onClick={(e) => {
                    setIsMobileMenuActive(false);
                  }}
                  to='/chats'>
                  <i className='fas fa-message'></i> Mesajlarım ({notReadMessageLength})
                </Link>
                <Link
                  onClick={(e) => {
                    setIsMobileMenuActive(false);
                  }}
                  to='/company/add'>
                  <i className='fas fa-plus'></i> Firma Oluşturma Talebi
                </Link>
                <Link
                  onClick={(e) => {
                    setIsMobileMenuActive(false);
                  }}
                  to='/ads'>
                  <i className='fas fa-plus'></i> Reklam Oluştur{' '}
                </Link>
                <Logout icon={<i className='fa-solid fa-arrow-right-from-bracket'></i>} />
              </nav>
            </div>

            <Link className={styles['navbar__nav__link']} to={'/companies'}>
              Firmalar
            </Link>

            <Link className={styles['navbar__nav__link']} to={'/myProfile'}>
              <i className='fas fa-user'></i>
            </Link>
          </nav>
        )}
      </div>
    </div>
  );
};

export default MobileNavbar;
