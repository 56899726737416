import React, { useEffect, useState } from 'react';

import styles from './UpdateCompany.module.scss';

import img from '../../../images/no.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { categoryGetAll } from '../../../redux/actions/categoryActions';
import {
  companyAdd,
  companyGetById,
  companyGetPendingUpdate,
  companyGetPendingUpdateById,
  requestCompanyUpdate,
} from '../../../redux/actions/companyActions';
import { Link, Navigate, useParams } from 'react-router-dom';
import { phoneNumberSettings } from '../../../hooks/phoneNumberSettings';
import { loadingStateChanged } from '../../../redux/actions/loadingStateActions';
import {
  getAllCitiesByCountryId,
  getAllCountries,
  getAllTonwsByCityId,
} from '../../../redux/actions/geographyActions';
import MultipleSelect from '../../../components/multiple-select/MultipleSelect';
import { Helmet } from 'react-helmet';
import fileControl from '../../../hooks/fileControl';

const updateCompanyHandler = (e) => {
  e.preventDefault();
};

const UpdateCompany = () => {
  // TownList
  const [townList, setTownList] = useState([]);
  const companyId = useParams().id;

  const [isRedirect, setIsRedirect] = useState(false);

  //inputs
  const [name, setName] = useState();
  const [address, setAddress] = useState();
  const [postCode, setPostCode] = useState();
  const [city, setCity] = useState();
  const [town, setTown] = useState();
  const [phone, setPhone] = useState();
  const [categoryId, setCategoryId] = useState();
  const [cityId, setCityId] = useState();
  const [townId, setTownId] = useState();
  const [countryId, setCountryId] = useState();
  const [description, setDescription] = useState();
  const [imageUrl, setImage] = useState('');
  const [base64SelectImage, setBase64SelectImage] = useState('');

  const [isSuccess, setIsSuccess] = useState(false);

  //redux settings
  const dispatch = useDispatch();
  const categoryList = useSelector((state) => state.categoriesReducer);
  const error = useSelector((state) => state.errorReducer)?.companyUpdate;
  const user = useSelector((state) => state.userReducer);
  const citiesAndTowns = useSelector((state) => state.citiesAndTownsReducer);
  const currentCompany = useSelector((state) => {
    const pendingUpdateCompany = state.getByIdPendingUpdateReducer;
    if (!pendingUpdateCompany?.id) {
      return state.companyGetByReducer;
    } else {
      return { ...state.companyGetByReducer, ...pendingUpdateCompany };
    }
  });

  const geography = useSelector((state) => state.geographyReducer);
  const [selectedCountry, setSelectedCountry] = useState([]);

  const actions = {
    getAllCategory: bindActionCreators(categoryGetAll, dispatch),
    companyGetById: bindActionCreators(companyGetById, dispatch),
    updateCompany: bindActionCreators(requestCompanyUpdate, dispatch),
    getPendingUpdateCompanies: bindActionCreators(companyGetPendingUpdate, dispatch),
    getByIdPendingUpdate: bindActionCreators(companyGetPendingUpdateById, dispatch),
    getAllCountries: bindActionCreators(getAllCountries, dispatch),
    getAllCitiesByCountryId: bindActionCreators(getAllCitiesByCountryId, dispatch),
    getAllTonwsByCityId: bindActionCreators(getAllTonwsByCityId, dispatch),
  };

  const countrySelected = (countryId) => {
    actions.getAllCitiesByCountryId(countryId);
  };

  const citySelected = (cityId) => {
    actions.getAllTonwsByCityId(cityId);
  };

  useEffect(() => {
    actions.getAllCategory();
    actions.companyGetById(companyId).then((result) => !result && setIsRedirect(!result));
    actions.getByIdPendingUpdate(companyId);
    actions.getPendingUpdateCompanies();
    actions.getAllCountries();
  }, []);

  useEffect(() => {
    setName(currentCompany.name);
    setAddress(currentCompany.address);
    setPostCode(currentCompany.postCode);
    setPhone(currentCompany.phone);
    setCategoryId(currentCompany.categoryId);
    setDescription(currentCompany.description);

    setImage(currentCompany.image);
    setSelectedCountry(currentCompany?.countries?.map((x) => ({ id: x.id, value: x.name })) || []);
    if (currentCompany.cityId && currentCompany.countryId > 0 && currentCompany.cityId > 0) {
      countrySelected(currentCompany.countryId);
      citySelected(currentCompany.cityId);
      setCountryId(currentCompany.countryId);
    }

    setCityId(currentCompany.cityId);
    setTownId(currentCompany.townId);
  }, [currentCompany.name, currentCompany.countryId]);

  const updateHandler = () => {
    const formRef = document.querySelector('#updateCompanyForm');
    const form = new FormData(formRef);
    actions.updateCompany(form).then((result) => result == true && setIsSuccess(result));
  };

  useEffect(() => {
    if (user.id && currentCompany.userId && currentCompany.userId != user.id && !user.isAdmin) {
      setIsRedirect(true);
    }
  }, [currentCompany, user]);

  //loading state
  const isLoadingState = useSelector((state) => state.loadingStateReducer);
  useEffect(() => {
    if (isLoadingState.companyUpdate === 'ok') {
      setIsSuccess(true);
      dispatch(loadingStateChanged({ companyUpdate: false }));
    }
  }, [isLoadingState]);

  const siteSettings = useSelector((state) => state.siteSettingsReducer);

  return (
    <div className={styles['page']}>
      <Helmet>
        <title>
          {'Firma Güncelleme - ' +
            (siteSettings.title || "Nakliyecim.net | Türkiye'nin En Kolay Nakliye Platformu")}
        </title>
        <meta
          name='description'
          content={
            siteSettings.description ||
            "Nakliyecim.net ile Türkiye'nin her yerine kolayca nakliye hizmeti alın. Binlerce nakliye firması ve ilanı arasından seçim yapın, en uygun fiyatlı nakliye çözümünü bulun."
          }
        />
        <meta
          name='keywords'
          content={
            siteSettings.keywords ||
            'nakliye, nakliye ilanları, nakliye firmaları, nakliyecim.net, eşya taşıma, yük taşıma, parsiyel taşıma, uygun fiyatlı nakliye, Türkiye'
          }
        />
        {/* Open Graph Elements */}
        <meta property='og:locale' content='tr_TR' />
        <meta property='og:type' content='article' />
        <meta
          property='og:title'
          content='Nakliyecim.net ile artık nakliyecileri bulmak o kadar da zor değil. '
        />
        <meta
          property='og:description'
          content='Nakliye Firmaları ve nakliye ilanlarını bulabilirsiniz. Hemen Kayıt olarak sizler de aramıza katıla bilirsiniz.'
        />
        <meta property='og:url' content='https://nakliyecim.net/about' />
        <meta property='og:image' content={siteSettings.logoPath} />
      </Helmet>
      {error && (
        <div className={styles['errors']}>
          <p className={styles['error']}>{error}</p>
        </div>
      )}
      <form
        onSubmit={(e) => e.preventDefault()}
        id='updateCompanyForm'
        className={styles['page__form']}
        encType='multipart/form-data'>
        <h1 className={styles.title}>Firma Güncelle</h1>
        <div className={styles['form-control']}>
          <label className={styles['files-label']} htmlFor='imageFile'>
            Firma Fotoğrafı Değiştir (Zorunlu Değil)
          </label>
          <input
            onChange={(e) => fileControl(e, setBase64SelectImage)}
            style={{ opacity: 0, height: 0 }}
            type='file'
            id='imageFile'
            name='imageFile'
          />
          <img
            width={200}
            height={200}
            style={{ objectFit: 'cover' }}
            src={base64SelectImage || currentCompany.image || img}
          />
        </div>
        <div className={styles['form-control']}>
          <label className='top' htmlFor='name'>
            Firma Adı
          </label>
          <input
            defaultValue={name}
            onChange={(e) => setName(e.target.value)}
            onBlur={(e) =>
              e.target.value.length > 0
                ? e.target.previousElementSibling.classList.add('top')
                : e.target.previousElementSibling.classList.remove('top')
            }
            onFocus={(e) => e.target.previousElementSibling.classList.add('top')}
            type='text'
            id='name'
            name='name'
            required
          />
        </div>
        <div className={styles['form-control']}>
          <label className='top' htmlFor='address'>
            Firma Adresi
          </label>
          <input
            defaultValue={address}
            onChange={(e) => setAddress(e.target.value)}
            onBlur={(e) =>
              e.target.value.length > 0
                ? e.target.previousElementSibling.classList.add('top')
                : e.target.previousElementSibling.classList.remove('top')
            }
            onFocus={(e) => e.target.previousElementSibling.classList.add('top')}
            type='text'
            id='address'
            name='address'
            required
          />
        </div>
        <div className={styles['form-control']}>
          <label className='top' htmlFor='postcode'>
            Posta Kodu
          </label>
          <input
            defaultValue={postCode}
            onChange={(e) => setPostCode(e.target.value)}
            onBlur={(e) =>
              e.target.value.length > 0
                ? e.target.previousElementSibling.classList.add('top')
                : e.target.previousElementSibling.classList.remove('top')
            }
            onFocus={(e) => e.target.previousElementSibling.classList.add('top')}
            type='number'
            id='postcode'
            name='postcode'
            required
          />
        </div>
        <div className={styles['form-control']}>
          <label className='top' htmlFor='phone'>
            Telefon Numarası
          </label>
          <input
            defaultValue={phone}
            onChange={(e) => {
              phoneNumberSettings(e);
              setPhone(e.target.value);
            }}
            type='text'
            onBlur={(e) =>
              e.target.value.length > 0
                ? e.target.previousElementSibling.classList.add('top')
                : e.target.previousElementSibling.classList.remove('top')
            }
            onFocus={(e) => e.target.previousElementSibling.classList.add('top')}
            id='phone'
            name='phone'
            required
          />
        </div>
        <div className={styles['form-control']}>
          <label className='top' htmlFor='categoryId'>
            Firma Kategorisi Seç
          </label>
          <select
            defaultValue={categoryId}
            onChange={(e) => setCategoryId(e.target.value)}
            onBlur={(e) =>
              e.target.value.length > 0
                ? e.target.previousElementSibling.classList.add('top')
                : e.target.previousElementSibling.classList.remove('top')
            }
            onFocus={(e) => e.target.previousElementSibling.classList.add('top')}
            type='number'
            id='categoryId'
            name='categoryId'
            required>
            <option value='' disabled></option>
            {categoryList?.map((category, i) => (
              <option key={i} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>
        {currentCompany.isInternational && (
          <div className={styles['form-control']}>
            <label className={styles['form-control__label']} htmlFor='imageFile'></label>
            <input name='IsInternational' value={true} hidden />
            {selectedCountry?.map((x, i) => (
              <input key={i} name={`countryIds[${i}]`} value={x.id} hidden />
            ))}

            <MultipleSelect
              data={geography.countries.map((country) => ({
                id: country.id,
                value: country.name,
              }))}
              label='Hizmet verdiğiniz uluslar arası ülkeleri seçin (birden fazla)'
              placeholder='ülke ara'
              selectedData={selectedCountry}
              setSelectedData={setSelectedCountry}
            />
          </div>
        )}
        <div className={styles['form-control']}>
          <label className='top' htmlFor='city'>
            Ülke Seçiniz
          </label>
          <select
            name='countryId'
            value={countryId}
            onChange={(e) => {
              countrySelected(e.target.value);
              setCountryId(e.target.value);
              setCity(0);
              setTownId(0);
            }}
            onBlur={(e) =>
              e.target.value.length > 0
                ? e.target.previousElementSibling.classList.add('top')
                : e.target.previousElementSibling.classList.remove('top')
            }
            onFocus={(e) => e.target.previousElementSibling.classList.add('top')}
            type='number'
            required>
            <option value={0}>Firmanızın bulunduğu ülkeyi seçin...</option>
            {geography.countries.map((country, i) => (
              <option value={country.id} key={i}>
                {country.name}
              </option>
            ))}
          </select>
        </div>
        <div className={styles['form-control']}>
          <label className='top' htmlFor='city'>
            Şehir Seçiniz
          </label>
          <select
            name='cityId'
            value={cityId}
            onChange={(e) => {
              citySelected(e.target.value);
              setCityId(e.target.value);
              setTownId(0);
            }}
            onBlur={(e) =>
              e.target.value.length > 0
                ? e.target.previousElementSibling.classList.add('top')
                : e.target.previousElementSibling.classList.remove('top')
            }
            onFocus={(e) => e.target.previousElementSibling.classList.add('top')}
            type='number'
            defaultValue={0}
            required>
            <option value={0}>Şehir Seç...</option>
            {geography.cities?.map((city, i) => (
              <option value={city.id} key={i}>
                {city.name}
              </option>
            ))}
          </select>
        </div>
        <div className={styles['form-control']}>
          <label htmlFor='townId'>İlçe Seçiniz.</label>
          <select
            id='townId'
            name='townId'
            value={townId}
            onChange={(e) => setTownId(e.target.value)}
            type='number'>
            <option>İlçe Seç...</option>
            {geography.towns?.map((t, i) => (
              <option value={t.id} key={i}>
                {t.name}
              </option>
            ))}
          </select>
        </div>
        <div className={styles['form-control']}>
          <label className='top' htmlFor='description'>
            Firma Açıklaması
          </label>
          <textarea
            defaultValue={description}
            onChange={(e) => setDescription(e.target.value)}
            rows={10}
            cols='100%'
            onBlur={(e) =>
              e.target.value.length > 0
                ? e.target.previousElementSibling.classList.add('top')
                : e.target.previousElementSibling.classList.remove('top')
            }
            onFocus={(e) => e.target.previousElementSibling.classList.add('top')}
            type='text'
            id='description'
            name='description'
            required></textarea>
        </div>
        <input type='hidden' name='oldImgPath' defaultValue={currentCompany.image} />
        <input type='number' hidden name='updatingCompanyId' defaultValue={companyId} />
        <input type='number' hidden name='userId' defaultValue={currentCompany.userId} />
        <input type='hidden' name='serverPath' defaultValue={currentCompany.serverPath} />
        <button onClick={updateHandler} className={styles['addBtn']} type='submit'>
          Firma Güncelleme Talebinde Bulun.
        </button>
      </form>
      {isRedirect && <Navigate to={'/'} />}
      {isSuccess && <Navigate to={'/myprofile'} />}
    </div>
  );
};

export default UpdateCompany;
