import React, { useEffect } from 'react';

import styles from './Home.module.scss';

import AdvertList from '../../components/advert-list/';
import CompanyList from '../../components/company-list/';
import { getUserInfo } from '../../redux/actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Helmet } from 'react-helmet';
import { siteSettingsGetAll } from '../../redux/actions/siteSettingsAction';

import headerBg from '../../images/header-bg.jpg';
import { Link } from 'react-router-dom';

const Home = () => {
  const siteSettings = useSelector((state) => state.siteSettingsReducer);
  const dispatch = useDispatch();
  const actions = {
    getSiteSettings: bindActionCreators(siteSettingsGetAll, dispatch),
  };

  useEffect(() => {
    actions.getSiteSettings();
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          {'Anasayfa - ' +
            (siteSettings.title || "Nakliyecim.net | Türkiye'nin En Kolay Nakliye Platformu")}
        </title>
        <meta
          name='description'
          content={
            siteSettings.description ||
            "Nakliyecim.net ile Türkiye'nin her yerine kolayca nakliye hizmeti alın. Binlerce nakliye firması ve ilanı arasından seçim yapın, en uygun fiyatlı nakliye çözümünü bulun."
          }
        />
        <meta
          name='keywords'
          content={
            siteSettings.keywords ||
            'nakliye, nakliye ilanları, nakliye firmaları, nakliyecim.net, eşya taşıma, yük taşıma, parsiyel taşıma, uygun fiyatlı nakliye, Türkiye'
          }
        />
        {/* Open Graph Elements */}
        <meta property='og:locale' content='tr_TR' />
        <meta property='og:type' content='article' />
        <meta
          property='og:title'
          content='Nakliyecim.net ile artık nakliyecileri bulmak o kadar da zor değil. '
        />
        <meta
          property='og:description'
          content='Nakliye Firmaları ve nakliye ilanlarını bulabilirsiniz. Hemen Kayıt olarak sizler de aramıza katıla bilirsiniz.'
        />
        <meta property='og:url' content='https://nakliyecim.net/about' />
        <meta property='og:image' content={siteSettings.logoPath} />
      </Helmet>
      <div className={styles['home']}>
        <header className={styles['home__header']}>
          <div className={styles['home__header__background']}></div>
          <img className={styles['home__header__image']} src={headerBg} alt='' />
          <div className={styles['home__header__contact']}>
            <h1 className={styles['home__header__contact__title']}>Nakliyecileri Bul</h1>
            <p className={styles['home__header__contact__description']}>
              Hemen ilan paylaşarak nakliyecilerin sizi aramasını sağlaya bilirsiniz.
            </p>
            <Link to={'/advert/add'} className={styles['home__header__contact__button']}>
              İlan Ver
            </Link>
          </div>
        </header>

        <h1 className={styles['home__section-title']}>Hizmetlerimiz</h1>
        <section className={styles['home__features']}>
          <div className={styles['home__features__feature']}>
            <img className={styles['home__features__feature__image']} src='' alt='' />
            <h1 className={styles['home__features__feature__title']}>İlanlar</h1>

            <p className={styles['home__features__feature__description']}>
              Kullanıcıların tamamı ilan paylaşa bilecek. Bu ilanların içerikleri firmalar için
              hazırlanmalıdır örneğin taşınması gereken bir yükünüz var bunun için firmalar arasında
              uygun bir firma bulamadınız bunun için ilan paylaşarak firmaların sizi görmesini
              sağlaya bilirsiniz. Veya sizin bir firmanız var ve elinizde hali hazırda bir iş var
              farklı firmaların haberdar olabilmesi için ilan paylaşa bilirsiniz.
            </p>
          </div>

          <div className={styles['home__features__feature']}>
            <img className={styles['home__features__feature__image']} src='' alt='' />
            <h1 className={styles['home__features__feature__title']}>Firmalar</h1>

            <p className={styles['home__features__feature__description']}>
              Firmalar arasından istediğiniz kategorideki firmayı rahat bir biçimde araya
              bilirsiniz. Eğer sizin bir firmanız var ise firmanızı web sitemizde yayınlamak için
              firma oluşturma sayfasından firmanızı ekleye bilirsiniz. firmanızın bilgilerini girip
              formu onayladıktan sonra firmanız incelemeye alınacaktır.
              İsimlendirme,açıklama,telefon numarası vb. içeriklerde herhangi bir sorun olmadığı
              sürece onaylanma işlemi gerçekleştirilecektir. Firmanız artık onatlı bir firma
              olduğunda firmalar arasında yerini alacaktır.
            </p>
          </div>

          <div className={styles['home__features__feature']}>
            <img className={styles['home__features__feature__image']} src='' alt='' />
            <h1 className={styles['home__features__feature__title']}>Reklam Sistemi</h1>

            <p className={styles['home__features__feature__description']}>
              Reklam sistemimiz sadece firma sahiplerini ilgilendiren bir konudur. firmalarınızın
              büyümesine yardımcı olması için aylık reklam sistemimizden yararlana bilirsiniz.
            </p>
          </div>
        </section>

        <h1 className={styles['home__section-title']}>Nasıl Kullanılır ?</h1>
        <section className={styles['home__how-to-use']}>
          <ul className={styles['home__how-to-use__list']}>
            <li className={styles['home__how-to-use__list__item']}>
              İlk olarak hesabınız yoksa kayıt olup
              <Link style={{ color: '#e95050' }} to='/auth'>
                <b> Giriş</b>
              </Link>{' '}
              yapın
            </li>

            <li className={styles['home__how-to-use__list__item']}>
              Giriş yaptıktan sonra{' '}
              <Link to={'/advert/add'} style={{ color: '#e95050' }}>
                İlan Ekleme
              </Link>{' '}
              Sayfasından ilanınızı oluştura bilirsiniz.
            </li>

            <li className={styles['home__how-to-use__list__item']}>
              Eğer Bir Firmanız varsa{' '}
              <Link style={{ color: '#e95050' }} to='/company/add'>
                <b>Firma Oluşturma Talebi</b>
              </Link>{' '}
              sayfasından firma oluşturma talebinde buluna bilirsiniz.
            </li>

            <li className={styles['home__how-to-use__list__item']}>
              Tüm işlemlerinizi firmanızın ayarlarını veya ilanlarınızın ayarlarını ve yönetimini{' '}
              <Link style={{ color: '#e95050' }} to='/auth'>
                <b>Profil</b>
              </Link>{' '}
              sayfasından yapabilirsiniz..
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
};

export default Home;
