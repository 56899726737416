import * as types from '../actionTypes/chatTypes';
import * as initialState from '../initialStates';

export default (state = initialState.chatsState, { type, payload }) => {
  switch (type) {
    case types.CREATE_NEW_CHAT_SUCCESS:
      return payload;

    case types.CREATE_NEW_CHAT_RESET:
      return [];
    default:
      return state;
  }
};
