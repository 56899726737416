import React, { useEffect, useRef } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import { useState } from 'react';
import { getUserInfo } from '../redux/actions/userActions';
import Navbar from '../components/navbar/Navbar';
import MobileNavbar from '../components/mobileNavbar';
import Header from '../components/header';
import { getAllMyChats, messageAdd } from '../redux/actions/chatActions';

import UseSignalr from '../hooks/useSignalr';

const IsLogginLayout = () => {
  const token = () => JSON.parse(localStorage.getItem('token'));

  const dispatch = useDispatch();
  const actions = {
    getUserInfo: bindActionCreators(getUserInfo, dispatch),
    getAllMyChats: bindActionCreators(getAllMyChats, dispatch),
  };
  const user = useSelector((state) => state.userReducer);

  useEffect(() => {
    if (token()) {
      actions.getUserInfo();
    }
  }, []);

  return (
    <div>
      <div style={{ paddingBottom: '30rem' }} className='container'>
        <Header />
        
        <Outlet />
        
        <MobileNavbar />
        {user.id && <UseSignalr />}
        {!token() && <Navigate to={`/auth?redirectUrl=${window.location.pathname}`} />}
      </div>
    </div>
  );
};

export default IsLogginLayout;
