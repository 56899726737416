import React, { useEffect, useState } from 'react';

import styles from './AdvertList.module.css';
import img from '../../images/no.jpg';

import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  advertGetAll,
  advertGetAuthorById,
  advertGetById,
} from '../../redux/actions/advertActions';
import Pagenations from '../pagenations';
import { categoryGetAll } from '../../redux/actions/categoryActions';
import Loading from '../loading/Loading';

const AdvertList = () => {
  const advertList = useSelector((state) => state.advertReducer);

  const [adverts, setAdverts] = useState(advertList);
  const categories = useSelector((state) => state.categoriesReducer);

  const [endIndex, setEndIndex] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const isLoadingState = useSelector((state) => state.loadingStateReducer);
  const dispatch = useDispatch();
  const actions = {
    getById: bindActionCreators(advertGetById, dispatch),
    getAll: bindActionCreators(advertGetAll, dispatch),
    getAuthorById: bindActionCreators(advertGetAuthorById, dispatch),
    getAllCategories: bindActionCreators(categoryGetAll, dispatch),
  };

  useEffect(() => {
    actions.getAll();
    actions.getAllCategories();
  }, []);

  useEffect(() => {
    setAdverts(advertList);
  }, [advertList]);

  const [selectedCategoryId, setSelectedCategoryId] = useState(0);

  useEffect(() => {
    if (selectedCategoryId === 0) {
      setAdverts(advertList);
    } else {
      setAdverts(advertList.filter((x) => x.categoryId === selectedCategoryId));
    }
  }, [selectedCategoryId]);

  return (
    <div>
      {isLoadingState.advertList ? (
        <Loading />
      ) : (
        <div>
          <div className={styles.header}>
            <h1 className={styles.title}>İlanlar</h1>
            <div className={styles.relative}>
              <div className={styles['category-filter']}>
                <select
                  onChange={(e) => setSelectedCategoryId(Number(e.target.value))}
                  name='category'
                  defaultValue='0'>
                  <option value={0}>Tüm Kategoriler</option>

                  {categories?.map((category, i) => (
                    <option key={i} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          {adverts.length > 0 ? (
            <ul className={styles['advert-list']}>
              {adverts?.slice(startIndex, endIndex).map((advert, i) => (
                <Link to={'/details/advert/' + advert.id} key={i} className={styles['advert-link']}>
                  <li className={styles['advert-list-item']}>
                    <img
                      className={styles['advert-img']}
                      src={advert.imageUrl || img}
                      width={150}
                      alt={advert.title}
                    />
                    <div className={styles.advert}>
                      <h3 className={styles['advert-title']}>{advert.title}</h3>
                      <div className={styles['advert-features']}>
                        <span className={styles['load-type']}>Yük Tipi: {advert.productType}</span>
                        <span className={styles['advert-distance']}>
                          <i className='fas fa-location-dot'></i> &nbsp;
                          {`${advert.fromWhere}/${advert.fromWhichCity} - ${advert.toWhere}/${advert.toWhichWhere}`}
                        </span>

                        {advert.offerPrice && (
                          <span className={styles['advert-offerPrice']}>
                            <i className='fa-solid fa-turkish-lira-sign'></i> &nbsp;
                            {advert.offerPrice}
                          </span>
                        )}
                      </div>
                    </div>
                  </li>
                </Link>
              ))}
            </ul>
          ) : (
            <h1 className={styles.empty}>Arama sonucunda ilan bulunamadı</h1>
          )}

          <Pagenations
            pageArray={adverts}
            showElementLength={20}
            setEnd={setEndIndex}
            setStart={setStartIndex}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      )}
    </div>
  );
};

export default AdvertList;
