import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './views/App';
import reportWebVitals from './reportWebVitals';
import { createStore } from './redux/stores/configureStore';
import { Provider } from 'react-redux';

const root = ReactDOM.createRoot(document.getElementById('root'));

const store = createStore();

root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);
reportWebVitals();
