import * as types from '../actionTypes/companyTypes'
import * as initialState from '../initialStates'

export default (state = initialState.companyState, { type, payload }) => {
    switch (type) {
        case types.COMPANY_GET_PENDİNG_UPDATE_SUCCESS:
            return payload
        default:
            return state
    }
}
