import * as actionTypes from '../actionTypes/companyTypes'
import * as initialStates from '../initialStates'


export const companyReducer = (state = initialStates.companyState, action) => {
    switch (action.type) {
        case actionTypes.COMPANY_GETALL_SUCCESS:
            var newState = action.payload;
            return newState;
        default:
            return state;
    }
}