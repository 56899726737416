import React, { useState } from 'react';
import AdminList from '../../../components/admin-components/AdminList/AdminList';

import img from '../../../images/no.jpg';
import AdminListItem from '../../../components/admin-components/AdminListItem/AdminListItem';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { advertGetAll, advertGetById } from '../../../redux/actions/advertActions';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const AdvertSettings = () => {
  const dispatch = useDispatch();

  const advertList = useSelector((state) => state.advertReducer);
  const currentAdvert = useSelector((state) => state.advertGetByReducer);
  const actions = {
    getAll: bindActionCreators(advertGetAll, dispatch),
    getById: bindActionCreators(advertGetById, dispatch),
  };

  useState(() => {
    actions.getAll();
  });

  let detailObj = {
    isActive: false,
    currentDetails: null,
  };

  const [isActiveDetail, setIsActiveDetail] = useState({ isActive: false });
  const siteSettings = useSelector((state) => state.siteSettingsReducer);

  const detailsHandler = (id) => {
    detailObj = {
      isActive: true,
      currentDetails: advertList.find((item) => item.id == id),
    };

    setIsActiveDetail(detailObj);
  };
  return (
    <div>
      <Helmet>
        <title>
          {'İlan Ayarları - ' +
            (siteSettings.title || "Nakliyecim.net | Türkiye'nin En Kolay Nakliye Platformu")}
          }
        </title>
        <meta
          name='description'
          content={
            siteSettings.description ||
            "Nakliyecim.net ile Türkiye'nin her yerine kolayca nakliye hizmeti alın. Binlerce nakliye firması ve ilanı arasından seçim yapın, en uygun fiyatlı nakliye çözümünü bulun."
          }
        />
        <meta
          name='keywords'
          content={
            siteSettings.keywords ||
            'nakliye, nakliye ilanları, nakliye firmaları, nakliyecim.net, eşya taşıma, yük taşıma, parsiyel taşıma, uygun fiyatlı nakliye, Türkiye'
          }
        />
        {/* Open Graph Elements */}
        <meta property='og:locale' content='tr_TR' />
        <meta property='og:type' content='article' />
        <meta
          property='og:title'
          content='Nakliyecim.net ile artık nakliyecileri bulmak o kadar da zor değil. '
        />
        <meta
          property='og:description'
          content='Nakliye Firmaları ve nakliye ilanlarını bulabilirsiniz. Hemen Kayıt olarak sizler de aramıza katıla bilirsiniz.'
        />
        <meta property='og:url' content='https://nakliyecim.net/about' />
        <meta property='og:image' content={siteSettings.logoPath} />
      </Helmet>
      <AdminList
        title='İlanlar'
        theads={[
          '#ID',
          'İlan Başlığı',
          'İlan Resmi',
          'Nereden ? - Nereye ?',
          'Ağırlık',
          'Fiyat Bilgisi',
          '#Kontroller',
        ]}>
        {advertList.map((item, i) => (
          <AdminListItem
            key={i}
            items={[
              item.id,
              item.title,
              <img width={50} src={item.imageUrl || img} />,
              item.fromWhere +
                '/' +
                item.fromWhichCity +
                '-' +
                item.toWhere +
                '/' +
                item.toWhichWhere,
              item.weight,
              item.offerPrice > 0 ? item.offerPrice + ' TL' : 'Belirtilmemiş',
              <>
                <Link to={'/advert/delete/' + item.id} className='advert-delete-btn'>
                  Kaldır
                </Link>
                <button className='advert-details-btn' onClick={() => detailsHandler(item.id)}>
                  Detay
                </button>
              </>,
            ]}
          />
        ))}
      </AdminList>

      {isActiveDetail.isActive && (
        <>
          <div
            className='details-popup-after'
            onClick={() => setIsActiveDetail({ isActive: false })}></div>
          <div className='details-popup'>
            <h1 className='details-popup-control'>
              Detaylar <span onClick={() => setIsActiveDetail({ isDetails: false })}>X</span>
            </h1>
            <div className='details'>
              {isActiveDetail.currentDetails ? (
                <p className='detail'>detay</p>
              ) : (
                <p>detaylar yüklenemedi...</p>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AdvertSettings;
