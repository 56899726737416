import * as actionTypes from '../actionTypes/companyTypes'
import * as initialStates from '../initialStates'


export const companyCrudReducer = (state = initialStates.companyState, action) => {
    switch (action.type) {
        case actionTypes.COMPANY_ADD_SUCCESS:
            var newState = action.payload;
            return newState;
        case actionTypes.COMPANY_DELETE_SUCCESS:
            var newState = action.payload;
            return newState;
        case actionTypes.COMPANY_SUCCESS_SUCCESS:
            var newState = action.payload;
            return newState;
        case actionTypes.COMPANY_UPDATE_SUCCESS:
            var newState = action.payload;
            return newState;
        case actionTypes.COMPANY_REQUEST_UPDATE_SUCCESS:
            var newState = action.payload;
            return newState;
        case actionTypes.COMPANY_CONFİRM_PENDİNG_UPDATE_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}