import * as types from '../actionTypes/companyTypes'
import * as initialStates from '../initialStates'

export default (state = initialStates.user, { type, payload }) => {
    switch (type) {

        case types.COMPANY_GET_AUTHOR_BY_ID:
            return { ...payload }

        default:
            return state
    }
}
