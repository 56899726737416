import * as actionTypes from '../actionTypes/advertTypes'
import * as initialStates from '../initialStates'


export const advertReducer = (state = initialStates.advertState, action) => {
    switch (action.type) {
        case actionTypes.ADVERT_ADD_SUCCESS:
            return state;
        case actionTypes.ADVERT_DELETE_SUCCESS:
            return state;
        case actionTypes.ADVERT_GETALL_SUCCESS:
            return action.payload;
        case actionTypes.ADVERT_UPDATE_SUCCESS:
            const newstate = state.map(advert => {
                if (advert.id == action.payload.id) {
                    return action.payload
                }

                return advert
            })

            return state = newstate;
        default:
            return state;
    }
}