import * as types from '../actionTypes/notificationTypes';
import * as initialState from '../initialStates';

export default (state = initialState.myCompanies, { type, payload }) => {
  switch (type) {
    case types.GET_ALL_NOTIFICATIONS_SUCCESS:
      return payload;

    default:
      return state;
  }
};
