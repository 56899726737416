import React, { useState } from 'react';

import styles from './HeaderTopNavbar.module.scss';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Logout from '../../../logout';
import noImage from '../../../../images/no.jpg';
import Loading from '../../../loading/Loading';
import TopUpBalanceBtn from '../../../TopUpBalanceBtn';
import Notifications from '../../../notifications/Notifications';

const HeaderTopNavbar = () => {
  const user = useSelector((state) => state.userReducer);
  const isLoadingState = useSelector((state) => state.loadingStateReducer);
  const [profileSubIsActive, setProfileSubIsActive] = useState(false);

  const notReadMessageLength = useSelector((state) => state.notReadMessageLength);

  return (
    <div className={styles['header-top']}>
      <div className={styles['header-top__navbar']}>
        <div className={styles['header-top__navbar__links']}>
          <Link to={'/'} className={styles['header-top__navbar__links__link']}>
            Anasayfa
          </Link>
          <Link to={'/companies'} className={styles['header-top__navbar__links__link']}>
            Nakliyat Firmaları
          </Link>
          <Link to={'/adverts'} className={styles['header-top__navbar__links__link']}>
            Yük İlanları
          </Link>

          {user && (
            <Link to={'/ads'} className={styles['header-top__navbar__links__link']}>
              Reklam Oluştur
            </Link>
          )}
        </div>
        <Link to={'/company/add'} className={styles['header-top__navbar__links__link__add']}>
          Firma Ekle
        </Link>
        <Link to={'/advert/add'} className={styles['header-top__navbar__links__link__add']}>
          Yük Ekle
        </Link>

        {!user?.username && (
          <div className={styles['header-top__navbar__nav']}>
            {isLoadingState.getProfile ? (
              <Loading />
            ) : (
              <Link to={'/auth'} className={styles['header-top__navbar__nav__link']}>
                <>
                  <p>
                    Hesap Aç &nbsp;
                    <i className='fa-solid fa-user-plus'></i>
                  </p>
                </>
              </Link>
            )}
          </div>
        )}
        {user?.username && (
          <div className={styles['header-top__navbar__nav']}>
            <Link to={'/chats'} className={styles['header-top__navbar__nav__messages']}>
              <i className='far fa-message'></i>
              {notReadMessageLength ? (
                <span className={styles['header-top__navbar__nav__messages__length']}>
                  {notReadMessageLength}
                </span>
              ) : (
                ''
              )}
            </Link>
            <Notifications></Notifications>

            {isLoadingState.getProfile && !user.username ? (
              <Loading />
            ) : (
              <>
                <div className={styles['header-top__navbar__nav__user-balance']}>
                  <span className={styles['header-top__navbar__nav__user-balance__balance']}>
                    Bakiye: {user.balance} ₺
                  </span>
                  <TopUpBalanceBtn />
                </div>

                <div
                  onClick={() => setProfileSubIsActive(!profileSubIsActive)}
                  className={styles['header-top__navbar__nav__profile']}>
                  <img
                    className={styles['header-top__navbar__nav__profile__image']}
                    height={50}
                    src={user.imageUrl || noImage}
                    alt='profil fotoğrafı nakliyecim.net'
                  />
                  <p className={styles['header-top__navbar__nav__profile__username']}>
                    {' '}
                    <i className='fas fa-user'></i> {user.fullname}
                  </p>
                  {profileSubIsActive && (
                    <>
                      <div
                        onClick={() => setProfileSubIsActive(!profileSubIsActive)}
                        className={styles['header-top__navbar__nav__profile__frame-closer']}></div>
                      <div className={styles['header-top__navbar__nav__profile__frame']}>
                        <Link
                          className={styles['header-top__navbar__nav__profile__frame__link']}
                          to={'/myProfile'}>
                          Profil
                        </Link>
                        <Link
                          className={styles['header-top__navbar__nav__profile__frame__link']}
                          to='/chats'>
                          <i className='fas fa-message'></i> Mesajlarım ({notReadMessageLength})
                        </Link>
                        <Link
                          className={styles['header-top__navbar__nav__profile__frame__link']}
                          to={'/company/add'}>
                          Firmanı Ekle
                        </Link>
                        <Link
                          className={styles['header-top__navbar__nav__profile__frame__link']}
                          to={'/advert/add'}>
                          Yük Ekle
                        </Link>

                        <Logout
                          icon={<i class='fa-solid fa-right-from-bracket'></i>}
                          className={styles['header-top__navbar__nav__profile__frame__link']}
                        />
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderTopNavbar;
