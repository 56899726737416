import React from 'react';

import styles from './Login.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { userLogin } from '../../../../redux/actions/userActions';
import { useEffect } from 'react';
import Loading from '../../../../components/loading/Loading';
import { siteSettingsGetAll } from '../../../../redux/actions/siteSettingsAction';
import { Helmet } from 'react-helmet';

const Login = () => {
  const user = useSelector((state) => state.userReducer);
  const error = useSelector((state) => state.errorReducer)?.auth;
  const isLoadingState = useSelector((state) => state.loadingStateReducer);
  const siteSettings = useSelector((state) => state.siteSettingsReducer);
  const dispatch = useDispatch();
  const actions = {
    login: bindActionCreators(userLogin, dispatch),
    getAllSiteSettings: bindActionCreators(siteSettingsGetAll, dispatch),
  };

  const loginHandler = (e) => {
    e.preventDefault();
    const form = document.querySelector('#loginForm');
    const formData = new FormData(form);

    actions.login(formData);
  };

  useEffect(() => {
    actions.getAllSiteSettings();
  }, []);

  return (
    <form id='loginForm' onSubmit={loginHandler} className={styles['login-form']}>
      <Helmet>
        <title>{'Giriş Yap - ' + siteSettings.title || 'Nakliyecim.net'}</title>
        <meta name='description' content={siteSettings.description} />
        <meta name='keywords' content={siteSettings.keywords} />
        {/* Open Graph Elements */}
        <meta property='og:locale' content='tr_TR' />
        <meta property='og:type' content='article' />
        <meta
          property='og:title'
          content='Nakliyecim.net ile artık nakliyecileri bulmak o kadar da zor değil. '
        />
        <meta
          property='og:description'
          content='Nakliye Firmaları ve nakliye ilanlarını bulabilirsiniz. Hemen Kayıt olarak sizler de aramıza katıla bilirsiniz.'
        />
        <meta property='og:url' content='https://nakliyecim.net/auth' />
        <meta property='og:image' content={siteSettings.logoPath} />
      </Helmet>

      <div className={styles.errors}>{error && <p>{error}</p>}</div>
      <div className={styles['login-form__control']}>
        <label className={styles['login-form__control__label']} htmlFor='username'>
          Kullanıcı Adınız *
        </label>
        <input
          type='text'
          id='username'
          placeholder='Kullanıcı Adı Giriniz.'
          name='username'
          className={styles['login-form__control__input']}
        />
      </div>
      <div className={styles['login-form__control']}>
        <label className={styles['login-form__control__label']} htmlFor='password'>
          Şifreniz *
        </label>
        <input
          type='password'
          id='password'
          placeholder='Parola Giriniz.'
          name='password'
          className={styles['login-form__control__input']}
        />
      </div>
      <div className={styles['login-form__control']}>
        <Link
          to={'/auth/forgot-password'}
          className={styles['login-form__control__forgot-password']}>
          Şifremi Unuttum{' '}
        </Link>
      </div>

      <button className={styles['login-form__button']}>
        {isLoadingState.auth ? <Loading /> : 'Giriş Yap'}
      </button>
      {user && user.username && <Navigate to={'/'} />}
    </form>
  );
};

export default Login;
