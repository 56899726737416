import * as actionTypes from '../actionTypes/chatTypes';
import * as initialStates from '../initialStates';

export default (state = initialStates.notReadMessageLength, action) => {
  switch (action.type) {
    case actionTypes.NOT_READ_MESSAGE_TOTAL:
      return action.payload;
    case actionTypes.NOT_READ_MESSAGE_INCREASE:
      return (state += 1);

    default:
      return state;
  }
};
