import imageParseBase64 from './imageParseBase64';

export default (e, setter = (text) => {}) => {
  var type = e.target.files[0]?.type;
  var file = e.target.files[0];

  var types = ['image/jpeg', 'image/jpg', 'image/gif', 'image/png'];
  if (types.indexOf(type) == -1) {
    e.target.value = '';
    alert(
      'lütfen jpeg,jpg,gif,png türlerinden herhangi birine sahip olan dosya uzantılarını yükleyin',
    );
    return false;
  }
  //text set
  imageParseBase64(file, setter);
  return true;
};
