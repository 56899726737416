import React, { useEffect, useState } from 'react';
import styles from './Pagenation.module.scss';

const Pagenations = ({
  pageArray,
  setStart,
  setEnd,
  showElementLength = 5,
  scrollTo = true,
  currentPage,
  setCurrentPage,
}) => {
  const pageCount = Math.ceil(pageArray.length / showElementLength);

  useEffect(() => {
    var endIndex = currentPage * showElementLength;
    setEnd(endIndex);
    setStart(endIndex - showElementLength);
  }, [showElementLength, currentPage, pageCount]);

  return (
    <>
      {pageArray.length > 0 && pageCount > 1 && (
        <footer className={styles['footer']}>
          <button
            className={styles['footer-button']}
            onClick={() => {
              if (currentPage > 1) {
                setCurrentPage(currentPage - 1);
                if (scrollTo) {
                  window.scrollTo(0, 0);
                }
              }
            }}>
            <i className='fa-solid fa-circle-chevron-left'></i>
          </button>{' '}
          <span className={styles['footer-page-count']}>
            <input
              className={styles['footer-input']}
              type='number'
              value={currentPage}
              onChange={(e) => {
                var value = Number(e.target.value);
                value = value > pageCount ? pageCount : value;
                setCurrentPage(value);
                if (scrollTo) {
                  window.scrollTo(0, 0);
                }
              }}
            />
            / {pageCount}
          </span>{' '}
          <button
            className={styles['footer-button']}
            onClick={() => {
              if (pageCount > currentPage) {
                setCurrentPage(currentPage + 1);
                if (scrollTo) {
                  window.scrollTo(0, 0);
                }
              }
            }}>
            <i className='fa-solid fa-circle-chevron-right'></i>
          </button>
        </footer>
      )}
    </>
  );
};

export default Pagenations;
