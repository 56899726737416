import * as actionTypes from '../actionTypes/advertTypes'
import * as initialStates from '../initialStates'


export const advertGetByReducer = (state = initialStates.companyGetByState, action) => {
    switch (action.type) {
        case actionTypes.ADVERT_GET_BY_ID_SUCCESS:
            return action.payload;
        case actionTypes.ADVERT_GET_BY_NAME_SUCCESS:
            return action.payload;
        case actionTypes.ADVERT_GET_MY_SUCCESS:
            return action.payload;

        default:
            return state;
    }
}