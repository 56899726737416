import React from 'react'
import images from '../../images'

const Loading = () => {
  return (
    <div style={{display:"flex",alignItems:"center",justifyContent:"center",height:"100%",width:"100%"}}>
        <img width={20} src={images.loading}/>
    </div>
  )
}

export default Loading