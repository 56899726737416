import { bindActionCreators } from 'redux';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import styles from './AddCompany.module.scss';
import { categoryGetAll, categoryGetById } from '../../../redux/actions/categoryActions';
import { companyAdd } from '../../../redux/actions/companyActions';
import { siteSettingsGetAll } from '../../../redux/actions/siteSettingsAction';
import { Navigate } from 'react-router-dom';
import { loadingStateChanged } from '../../../redux/actions/loadingStateActions';
import Loading from '../../../components/loading/Loading';
import {
  getAllCitiesByCountryId,
  getAllCountries,
  getAllTonwsByCityId,
} from '../../../redux/actions/geographyActions';
import MultipleSelect from '../../../components/multiple-select/MultipleSelect';
import fileControl from '../../../hooks/fileControl';
const AddCompany = () => {
  const [TownList, setTownList] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [base64SelectImage, setBase64SelectImage] = useState('');

  const dispatch = useDispatch();
  const categoryList = useSelector((state) => state.categoriesReducer);
  const error = useSelector((state) => state.errorReducer)?.companyAdded;
  const geography = useSelector((state) => state.geographyReducer);
  const actions = {
    getAll: bindActionCreators(categoryGetAll, dispatch),
    companyadd: bindActionCreators(companyAdd, dispatch),
    getSiteSettings: bindActionCreators(siteSettingsGetAll, dispatch),
    getAllCountries: bindActionCreators(getAllCountries, dispatch),
    getAllCitiesByCountryId: bindActionCreators(getAllCitiesByCountryId, dispatch),
    getAllTonwsByCityId: bindActionCreators(getAllTonwsByCityId, dispatch),
  };

  const user = useSelector((state) => state.userReducer);

  const addCompanyHandler = async (e) => {
    e.preventDefault();
    const addFormRef = document.querySelector('#addForm');
    let formData = new FormData(addFormRef);

    actions.companyadd(formData);
  };

  const countrySelected = (countryId) => {
    actions.getAllCitiesByCountryId(countryId);
    actions.getAllTonwsByCityId(0);
  };

  const citySelected = (cityId) => {
    actions.getAllTonwsByCityId(cityId);
  };

  useEffect(() => {
    actions.getAll();
    actions.getSiteSettings();
    actions.getAllCountries();

    if (isLoadingState.companyAdded === 'ok') {
      setIsSuccess(true);
    }
  }, []);

  const siteSettings = useSelector((state) => state.siteSettingsReducer);

  //loading state
  const isLoadingState = useSelector((state) => state.loadingStateReducer);
  useEffect(() => {
    if (isLoadingState.companyAdded === 'ok') {
      setIsSuccess(true);
      dispatch(loadingStateChanged({ companyAdded: false }));
    }
  }, [isLoadingState]);

  const [selectedCountry, setSelectedCountry] = useState([]);
  const [isInternational, setIsInternational] = useState(false);

  return (
    <div className={styles['page']}>
      <Helmet>
        <title>
          {'Firma Oluşturma Talebi - ' +
            (siteSettings.title || "Nakliyecim.net | Türkiye'nin En Kolay Nakliye Platformu")}
        </title>
        <meta
          name='description'
          content={
            siteSettings.description ||
            "Nakliyecim.net ile Türkiye'nin her yerine kolayca nakliye hizmeti alın. Binlerce nakliye firması ve ilanı arasından seçim yapın, en uygun fiyatlı nakliye çözümünü bulun."
          }
        />
        <meta
          name='keywords'
          content={
            siteSettings.keywords ||
            'nakliye, nakliye ilanları, nakliye firmaları, nakliyecim.net, eşya taşıma, yük taşıma, parsiyel taşıma, uygun fiyatlı nakliye, Türkiye'
          }
        />
        {/* Open Graph Elements */}
        <meta property='og:locale' content='tr_TR' />
        <meta property='og:type' content='article' />
        <meta
          property='og:title'
          content='Nakliyecim.net ile artık nakliyecileri bulmak o kadar da zor değil. '
        />
        <meta
          property='og:description'
          content='Nakliye Firmaları ve nakliye ilanlarını bulabilirsiniz. Hemen Kayıt olarak sizler de aramıza katıla bilirsiniz.'
        />
        <meta property='og:url' content='https://nakliyecim.net/about' />
        <meta property='og:image' content={siteSettings.logoPath} />
      </Helmet>
      <h1 className={styles['page__title']}>Hizmet Seç</h1>
      <div className={styles['page__company-types']}>
        <div
          onClick={() => setIsInternational(true)}
          active={isInternational ? 'true' : 'false'}
          className={styles['page__company-types__type']}>
          Uluslar Arası Hizmet
        </div>
        <div
          onClick={() => setIsInternational(false)}
          active={!isInternational ? 'true' : 'false'}
          className={styles['page__company-types__type']}>
          Tek Bir Ülkeye Hizmet
        </div>
      </div>
      <form id='addForm' className={styles['page__form']}>
        <h1 className={styles['page__form__title']}>
          {isInternational ? 'Uluslar Arası Hizmet' : 'Tek Bir Ülkeye Hizmet'} firması oluşturma
          talebinde bulun
        </h1>
        {error && (
          <div className={styles['errors']}>
            <p className={styles['error']}>{error}</p>
          </div>
        )}

        <div className={styles['form-control']}>
          <div className={styles['form-control']}>
            <label className={styles['form-control__files-label']} htmlFor='imageFile'>
              Firma Fotoğrafı Yükle (Zorunlu Değil)
            </label>
            <input
              onChange={(e) => fileControl(e, setBase64SelectImage)}
              style={{ opacity: 0, height: 0 }}
              type='file'
              id='imageFile'
              className={styles['form-control__files-input']}
              name='imageFile'
            />
            {base64SelectImage && <img src={base64SelectImage} width={150} />}
          </div>
          <label className={styles['form-control__label']} htmlFor='name'>
            Firma Adı
          </label>
          <input
            className={styles['form-control__input']}
            placeholder='Firma Adı'
            id='name'
            name='name'
          />
        </div>

        <div className={styles['form-control']}>
          <label className={styles['form-control__label']} htmlFor='address'>
            Firma Adresi
          </label>
          <input
            className={styles['form-control__input']}
            placeholder='Firma Adresi'
            id='address'
            name='address'
          />
        </div>

        <div className={styles['form-control']}>
          <label className={styles['form-control__label']} htmlFor='postCode'>
            Posta Kodu
          </label>
          <input
            className={styles['form-control__input']}
            placeholder='Posta Kodu'
            id='postCode'
            name='postCode'
          />
        </div>

        <div className={styles['form-control']}>
          <label className={styles['form-control__label']} htmlFor='phone'>
            Telefon Numarası
          </label>
          <input
            className={styles['form-control__input']}
            placeholder='Telefon Numarası'
            id='phone'
            name='phone'
          />
        </div>

        <div className={styles['form-control']}>
          <label className={styles['form-control__label']} htmlFor='categoryId'>
            Firma Kategorisi Seçin.
          </label>
          <select className={styles['form-control__input']} id='categoryId' name='categoryId'>
            <option>Kategori Seç</option>
            {categoryList?.map((c, i) => (
              <option key={i} value={c.id}>
                {c.name}
              </option>
            ))}
          </select>
        </div>

        {isInternational && (
          <div className={styles['form-control']}>
            <label className={styles['form-control__label']} htmlFor='imageFile'></label>
            <input name='IsInternational' value={true} hidden />
            {selectedCountry.map((x, i) => (
              <input key={i} name={`countryIds[${i}]`} value={x.id} hidden />
            ))}

            <MultipleSelect
              data={geography.countries.map((country) => ({
                id: country.id,
                value: country.name,
              }))}
              label='Hizmet verdiğiniz uluslar arası ülkeleri seçin (birden fazla)'
              placeholder='ülke ara'
              selectedData={selectedCountry}
              setSelectedData={setSelectedCountry}
            />
          </div>
        )}
        <div className={styles['form-control']}>
          <label className={styles['form-control__label']} htmlFor='countryId'>
            Firmanız hangi ülkede ?
          </label>
          <select
            onChange={(e) => countrySelected(e.target.value)}
            className={styles['form-control__input']}
            id='countryId'
            name='countryId'>
            <option>Ülke Seç</option>
            {geography.countries.map((c, i) => (
              <option value={c.id} key={i}>
                {c.name}
              </option>
            ))}
          </select>
        </div>

        {geography.cities && (
          <div className={styles['form-control']}>
            <label className={styles['form-control__label']} htmlFor='cityId'>
              Şehir Seç
            </label>
            <select
              onChange={(e) => citySelected(e.target.value)}
              className={styles['form-control__input']}
              id='cityId'
              name='cityId'>
              <option>Şehir Seç</option>
              {geography.cities.map((c, i) => (
                <option key={i} value={c.id}>
                  {c.name}
                </option>
              ))}
            </select>
          </div>
        )}

        {geography.towns.length != 0 && (
          <div className={styles['form-control']}>
            <label className={styles['form-control__label']} htmlFor='town'>
              İlçe Seç
            </label>
            <select className={styles['form-control__input']} id='townId' name='townId'>
              <option>İlçe Seç</option>
              {geography.towns.map((c, i) => (
                <option key={i} value={c.id}>
                  {c.name}
                </option>
              ))}
            </select>
          </div>
        )}

        <div className={styles['form-control']}>
          <label className={styles['form-control__label']} htmlFor='description'>
            Firma Açıklaması
          </label>
          <textarea
            rows={10}
            cols={150}
            className={styles['form-control__input']}
            placeholder='Firma Açıklaması'
            id='description'
            name='description'
          />
        </div>

        <input type='hidden' name='userId' value={user?.id} />

        {isLoadingState.companyAdded ? (
          <Loading />
        ) : (
          <button
            onClick={addCompanyHandler}
            className={styles['page__form__button']}
            type='submit'>
            Firma Ekleme Talebinde Bulun.
          </button>
        )}
      </form>

      {isSuccess && <Navigate to={'/myProfile'} />}
    </div>
  );
};

export default AddCompany;
