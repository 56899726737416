import React, { useEffect } from 'react';

import styles from './Alert.module.scss';
import { bindActionCreators } from 'redux';
import { siteSettingsGetAll } from '../../../../redux/actions/siteSettingsAction';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

const Alert = ({ type }) => {
  const siteSettings = useSelector((state) => state.siteSettingsReducer);
  const dispatch = useDispatch();
  const actions = {
    getSiteSettings: bindActionCreators(siteSettingsGetAll, dispatch),
  };

  useEffect(() => {
    actions.getSiteSettings();
  }, []);

  return (
    <>
      {siteSettings.isAnnouncement && (
        <h3 type='alert' className={styles[siteSettings.announcementType || 'success']}>
          <i className='fa-solid fa-bullhorn'></i> {siteSettings.announcementTitle} :
          <span className={styles['alert-text']}>{siteSettings.announcementText}</span>
        </h3>
      )}
    </>
  );
};

export default Alert;
