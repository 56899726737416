import * as actionTypes from '../actionTypes/errorTypes';
import * as initialStates from '../initialStates';

export const errorReducer = (state = initialStates.companyState, action) => {
  switch (action.type) {
    case actionTypes.ADD_ERRORS:
      var newState = { ...state, ...action.payload };
      return newState;

    default:
      return state;
  }
};
