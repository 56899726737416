import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getUserInfo } from '../../redux/actions/userActions';

import styles from './BalanceOrder.module.css';
import { useState } from 'react';
import { getPaymentData } from '../../redux/actions/paymentActions';
import images from '../../images';
import Loading from '../../components/loading/Loading';
import { Helmet } from 'react-helmet';

const BalanceOrder = () => {
  const dispatch = useDispatch();
  const companyAdsReducer = useSelector((state) => state.companyAdsReducer);
  const paymentData = useSelector((state) => state.getPaymentDataReducer);
  const loadingState = useSelector((state) => state.loadingStateReducer);
  const user = useSelector((state) => state.userReducer);

  const [balanceObject, setBalanceObject] = useState({
    userId: 0,
    balance: 10,
    username: user.username,
    address: user.address,
    email: user.email,
    phone: user.phone,
  });

  useEffect(() => {
    dispatch(getUserInfo());
  }, []);

  useEffect(() => {
    if (user.id) {
      setBalanceObject({
        ...balanceObject,
        username: user.username,
        userId: user.id,
        address: user.address,
        email: user.email,
        phone: user.phone,
      });
    }
  }, [user.id]);

  useEffect(() => {
    if (balanceObject.balance >= 10 && balanceObject.username) {
      dispatch(getPaymentData(balanceObject));
    }
  }, [balanceObject]);

  // useEffect(() => {
  //   if (
  //     !companyAdsReducer.errors &&
  //     !companyAdsReducer &&
  //     companyAdsReducer.statusCode == 200 &&
  //     user.id
  //   ) {
  //     dispatch(adsGetMy(user.id));
  //   }
  // }, [companyAdsReducer]);

  // var currentAdsCompany = companyAdsReducer?.myCompanies || [];
  // var advertisingSuitableCompanies = companyAdsReducer?.advertisingSuitable || [];

  const url = new URL(window.location.href);
  const errorValue = url.searchParams.get('error');

  const successValue = url.searchParams.get('success');
  const siteSettings = useSelector((state) => state.siteSettingsReducer);

  return (
    <div className={styles['ads-page']}>
      <Helmet>
        <title>
          {'Hesabına Para Ekle - ' +
            (siteSettings.title || "Nakliyecim.net | Türkiye'nin En Kolay Nakliye Platformu")}
        </title>
        <meta
          name='description'
          content={
            siteSettings.description ||
            "Nakliyecim.net ile Türkiye'nin her yerine kolayca nakliye hizmeti alın. Binlerce nakliye firması ve ilanı arasından seçim yapın, en uygun fiyatlı nakliye çözümünü bulun."
          }
        />
        <meta
          name='keywords'
          content={
            siteSettings.keywords ||
            'nakliye, nakliye ilanları, nakliye firmaları, nakliyecim.net, eşya taşıma, yük taşıma, parsiyel taşıma, uygun fiyatlı nakliye, Türkiye'
          }
        />
        {/* Open Graph Elements */}
        <meta property='og:locale' content='tr_TR' />
        <meta property='og:type' content='article' />
        <meta
          property='og:title'
          content='Nakliyecim.net ile artık nakliyecileri bulmak o kadar da zor değil. '
        />
        <meta
          property='og:description'
          content='Nakliye Firmaları ve nakliye ilanlarını bulabilirsiniz. Hemen Kayıt olarak sizler de aramıza katıla bilirsiniz.'
        />
        <meta property='og:url' content='https://nakliyecim.net/about' />
        <meta property='og:image' content={siteSettings.logoPath} />
      </Helmet>
      <h1 className={styles['page-title']}>Bakiye Yükleme Formu</h1>
      <form action={paymentData.post_url} method='post' className={styles['payment-form']}>
        {companyAdsReducer?.errors && <h5>{companyAdsReducer.errors}</h5>}
        {errorValue && (
          <h5 className={styles['payment-error']}>
            Ödeme işlemi başarısız! detaylar için bildirimleri kontrol edin.
          </h5>
        )}

        {successValue && <h5 className={styles['payment-success']}>{successValue}</h5>}

        <div types='full' className={styles['form-item']}>
          <div types='full' className={styles['form-group']}>
            <label className={styles['form-item-title']} htmlFor='companyId'>
              Ne Kadar Bakiye Yüklemek İstersin ? (TL)
            </label>
            <input
              maxLength={6}
              min={10}
              onChange={(e) => {
                if (e.target.value.slice(0, 7) != balanceObject.balance.toString()) {
                  setBalanceObject({ ...balanceObject, balance: e.target.value.slice(0, 7) });
                }
              }}
              value={balanceObject.balance}
              type='number'
              placeholder='Bakiye Giriniz.'
            />
          </div>
        </div>

        {loadingState.paymentDataLoading ||
          (loadingState.getProfile ? (
            <Loading />
          ) : (
            <>
              {balanceObject.balance >= 10 && balanceObject.username ? (
                <div types='full'>
                  {loadingState.paymentDataLoading ? (
                    <Loading />
                  ) : (
                    <div>
                      <div types='full' className={styles['form-item']}>
                        <div className={styles['form-group']}>
                          <label className={styles['form-item-title']} htmlFor='companyId'>
                            Kart Üzerindeki İsim
                          </label>
                          <input
                            name='cc_owner'
                            className={styles['form-item-title-input']}
                            placeholder='Tam Ad giriniz'
                          />
                        </div>
                        <div className={styles['form-group']}>
                          <label className={styles['form-item-title']} htmlFor='companyId'>
                            Kart Numarası
                          </label>
                          <input
                            name='card_number'
                            onChange={(e) => (e.target.value = e.target.value.replaceAll(' ', ''))}
                            className={styles['form-item-title-input']}
                            placeholder='Kart numarası giriniz'
                          />
                        </div>
                        <div className={styles['form-group']}>
                          <label className={styles['form-item-title']} htmlFor='companyId'>
                            Ay
                          </label>
                          <input
                            name='expiry_month'
                            className={styles['form-item-title-input']}
                            placeholder='Kart üzerindeki (Ay)'
                          />
                        </div>
                        <div className={styles['form-group']}>
                          <label className={styles['form-item-title']} htmlFor='companyId'>
                            Yıl
                          </label>
                          <input
                            name='expiry_year'
                            className={styles['form-item-title-input']}
                            placeholder='Kart üzerindeki (yıl)'
                          />
                        </div>
                        <div types='full' className={styles['form-group']}>
                          <label className={styles['form-item-title']} htmlFor='companyId'>
                            Cvv
                          </label>
                          <input
                            name='cvv'
                            className={styles['form-item-title-input']}
                            placeholder='Cvv (Kartın arkasındaki 3 haneli kod)'
                          />
                        </div>
                      </div>

                      {/* <!-- Merchant Information --/> */}
                      <input type='hidden' name='merchant_id' value={paymentData.merchant_id} />
                      <input type='hidden' name='merchant_key' value={paymentData.merchant_key} />
                      <input type='hidden' name='merchant_salt' value={paymentData.merchant_salt} />
                      <input type='hidden' name='paytr_token' value={paymentData.paytr_token} />

                      {/* <!-- User Information --/> */}
                      <input type='hidden' name='user_ip' value={paymentData.user_ip} />
                      <input type='hidden' name='merchant_oid' value={paymentData.merchant_oid} />
                      <input type='hidden' name='email' value={paymentData.email} />
                      <input type='hidden' name='user_name' value={paymentData.user_name} />
                      <input type='hidden' name='user_address' value={paymentData.user_address} />
                      <input type='hidden' name='user_phone' value={paymentData.user_phone} />

                      {/* <!-- Payment Details --/> */}
                      <input type='hidden' name='payment_type' value={paymentData.payment_type} />
                      <input
                        type='hidden'
                        name='payment_amount'
                        value={paymentData.payment_amount}
                      />
                      <input
                        type='hidden'
                        name='installment_count'
                        value={paymentData.installment_count}
                      />
                      <input type='hidden' name='currency' value={paymentData.currency} />
                      <input type='hidden' name='non_3d' value={paymentData.non_3d} />
                      <input
                        type='hidden'
                        name='non3d_test_failed'
                        value={paymentData.non3d_test_failed}
                      />
                      <input
                        type='hidden'
                        name='no_installment'
                        value={paymentData.no_installment}
                      />
                      <input
                        type='hidden'
                        name='max_installment'
                        value={paymentData.max_installment}
                      />
                      <input type='hidden' name='lang' value={paymentData.lang} />

                      {/* <!-- Additional Information --/> */}
                      <input
                        type='hidden'
                        name='merchant_fail_url'
                        value={paymentData.merchant_fail_url}
                      />
                      <input
                        type='hidden'
                        name='merchant_ok_url'
                        value={paymentData.merchant_ok_url}
                      />
                      <input type='hidden' name='user_basket' value={paymentData.user_basket} />
                      <input type='hidden' name='debug_on' value={paymentData.debug_on} />
                      <input type='hidden' name='test_mode' value={paymentData.test_mode} />

                      <button types='full' type='submit' className={styles['create-btn']}>
                        Bakiye Yükle
                      </button>
                    </div>
                  )}
                  <p className={styles['not']}>
                    Kart bilgilerinizi doğru bir şekilde girdikten sonra ödeme işlemi
                    tamamlandığında bu pencereden çıkış yapabilirsiniz.
                    <br />
                    <b>nakliyecim.net</b> hesabınıza tekrar gelip sayfayı yenilediğinizde güncel
                    bakiyenizi görüntüleye bilirsiniz.
                    <br />
                    Eğer bilgileri doğru girdiyseniz bakiyenin hesaba geçmesi bazen zaman alabilir.
                  </p>
                </div>
              ) : (
                'Lütfen En Az 10 TL Yükleme Yapın'
              )}
            </>
          ))}
      </form>
    </div>
  );
};

export default BalanceOrder;
