import React from "react";
import { Link } from "react-router-dom";

import styles from "./Logout.module.css";
import { bindActionCreators } from "redux";
import { logout } from "../../redux/actions/userActions";
import { useDispatch } from "react-redux";

const Logout = (props) => {
  const dispatch = useDispatch();
  const actions = {
    logout: bindActionCreators(logout, dispatch),
  };

  const clickHandler = () => {
    localStorage.removeItem("token");
    actions.logout();
  };
  return (
    <Link
      onClick={clickHandler}
      style={{ color: props?.color }}
      to={"/"}
      className={props?.className}
    >
      {props?.icon} Çıkış Yap
    </Link>
  );
};

export default Logout;
