export default (file, setter = (base64Text) => {}) => {
  if (file) {
    const reader = new FileReader();

    reader.onload = function (e) {
      const base64Image = e.target.result;
      setter(base64Image);
    };

    reader.readAsDataURL(file);
  } else {
    setter('');
  }
};
