import React from 'react';
import { Link } from 'react-router-dom';

const TopUpBalance = () => {
  const handleOpenWindow = () => {
    const newWindow = window.open(
      'https://nakliyecim.net/balance',
      '_blank',
      'width=600,height=600',
    );
  };
  return <Link to={'/balance'}>Hesaba Bakiye Yükle</Link>;
};

export default TopUpBalance;
