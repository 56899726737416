import * as types from '../actionTypes/paymentTypes'
import * as initialStates from '../initialStates'

export default (state = initialStates.paymentData, { type, payload }) => {
    switch (type) {
        case types.GET_PAYMENT_DATA:
            return state = { ...payload }

        default:
            return state
    }
}
