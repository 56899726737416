import * as types from '../actionTypes/companyaAdsTypes';

import { myAxios } from '../myAxios';
import { loadingStateChanged } from './loadingStateActions';

export const CreateCompanyAdsSuccess = (data) => ({
  type: types.CREATE_COMPANY_ADS,
  payload: data,
});
export const CreateCompanyAdsFail = (data) => ({
  type: types.CREATE_COMPANY_ADS,
  payload: data,
});

export const CreateCompanyAds = (createCompanyDto) => {
  return async (dispatch) => {
    dispatch(loadingStateChanged({ createCompanyAds: true }));
    const response = await myAxios().post('api/ads/create/companyAds', createCompanyDto);
    const data = await response.data;
    if (data.error) {
      dispatch(CreateCompanyAdsFail(data.error));
    }
    dispatch(loadingStateChanged({ createCompanyAds: false }));
    dispatch(CreateCompanyAdsSuccess(data.data));
  };
};

export const getActiveMyCompanyAdsSuccess = (data) => ({
  type: types.GET_ACTİVE_MY_ADS,
  payload: data,
});

export const getActiveMyCompanyAdsFail = (data) => ({
  type: types.GET_ACTİVE_MY_ADS_FAIL,
  payload: data,
});

export const getActiveMyCompanyAds = (userId) => {
  return async (dispatch) => {
    dispatch(loadingStateChanged({ companyAdsGetLoading: true }));
    const response = await myAxios().get('api/ads/my/' + userId);
    const data = await response.data;
    if (data.error) {
      dispatch(getActiveMyCompanyAdsFail(data.error));
    }
    dispatch(getActiveMyCompanyAdsSuccess(data.data));
    dispatch(loadingStateChanged({ companyAdsGetLoading: false }));
  };
};

export const getMyCompaniesForAdsSuccess = (data) => ({
  type: types.GET_MY_COMPANİES_FOR_ADS,
  payload: data,
});

export const getMyCompaniesForAdsFail = (data) => ({
  type: types.GET_MY_COMPANİES_FOR_ADS_FAIL,
  payload: data,
});

export const getMyCompaniesForAds = (userId) => {
  return async (dispatch) => {
    dispatch(loadingStateChanged({ companyAdsGetLoading: true }));
    const response = await myAxios().get('api/ads/my/companies/' + userId);
    const data = await response.data;
    if (data.error) {
      dispatch(getMyCompaniesForAdsFail(data.error));
    }
    dispatch(getMyCompaniesForAdsSuccess(data.data));
    dispatch(loadingStateChanged({ companyAdsGetLoading: true }));
  };
};

export const getCompanyAdsesRandomSuccess = (data) => ({
  type: types.GET_COMPANY_ADSES_RANDOM,
  payload: data,
});

export const getCompanyAdsesRandomFail = (data) => ({
  type: types.GET_COMPANY_ADSES_RANDOM_FAIL,
  payload: data,
});

export const getCompanyAdsesRandom = () => {
  return async (dispatch) => {
    dispatch(loadingStateChanged({ companyAdsGetLoading: true }));
    const response = await myAxios().get('api/ads');
    const data = await response.data;
    if (data.error) {
      dispatch(getCompanyAdsesRandomFail(data.error));
    }
    dispatch(getCompanyAdsesRandomSuccess(data.data));
    dispatch(loadingStateChanged({ companyAdsGetLoading: true }));
  };
};

// export const adsCreateCompaniesSuccess = (datas) => ({
//   type: types.ADS_CREATE_SUCCESS,
//   payload: datas,
// });

// export const adsCreateCompanies = (data) => {
//   return async (dispatch) => {
//     const response = await myAxios().post("api/companyAds/", data);
//     const datas = await response.data;

//     dispatch(adsCreateCompaniesSuccess(datas));
//   };
// };

// export const getCompanyAdsRandomSuccess = (datas) => ({
//   type: types.ADS_GET_RANDOM_SUCCESS,
//   payload: datas,
// });

// export const getCompanyAdsRandom = (amount) => {
//   return async (dispatch) => {
//     const response = await myAxios().get("api/companyAds/length/" + amount);
//     const datas = await response.data.data;

//     dispatch(adsCreateCompaniesSuccess(datas));
//   };
// };
