import React from 'react'

import styles from './NoPermission.module.css'
import { Link } from 'react-router-dom'
const NoPermission = () => {
    return (
        <div>
            <div className={styles.page}>
                <p><span className={styles.errorcode}>Yetkiniz Yok &nbsp;</span><span className={styles.errortext}>-&nbsp; Bu Sayfayı Kullana Bilmek İçin Giriş Yapmalısınız.</span></p>
                <p><Link className={styles.link} to={"/auth"}>Kullanıcı Girişi</Link></p>
            </div>
        </div>
    )
}

export default NoPermission
