import * as types from '../actionTypes/userTypes';
import * as adsTypes from '../actionTypes/companyaAdsTypes';
import * as initialState from '../initialStates';

export default (state = initialState.user, { type, payload }) => {
  switch (type) {
    case types.USER_LOGİN_SUCCESS:
      return { ...state };

    case types.USER_REGİSTER_SUCCESS:
      return { ...state };

    case types.USER_GET_INFO_SUCCESS:
      return payload || {};

    case types.USER_LOGOUT_SUCCESS:
      return (state = { ...payload });

    case types.USER_CHANGE_PROFİLE_IMAGE_SUCCESS:
      return { ...state };

    case types.USER_MAKE_ADMİN_SUCCESS:
      return { ...state };
    default:
      return state;
  }
};
