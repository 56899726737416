import * as types from '../actionTypes/companyTypes'
import * as initialState from '../initialStates'

export default (state = initialState.myCompanies, { type, payload }) => {
    switch (type) {

        case types.COMPANY_GET_MY_SUCCESS:
            return payload

        default:
            return state
    }
}
