import * as types from '../actionTypes/citiesAndTownsTypes'
import * as initialStates from '../initialStates'

export default (state = initialStates.user, { type, payload }) => {
    switch (type) {

        case types.GET_ALL_CITIES_SUCCESS:
            return state = { ...payload }

        default:
            return state
    }
}
