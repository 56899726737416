import * as types from '../actionTypes/companyRatingTypes';

import { myAxios } from '../myAxios';
import { errorAction } from './errorActions';
import { loadingStateChanged } from './loadingStateActions';

//get all ratings
export const getAllCompanyRatingsSuccess = (data) => ({
  type: types.COMPANY_GET_ALL_RATINGS_SUCCESS,
  payload: data,
});

export const getAllCompanyRatings = (companyId) => {
  return async (dispatch) => {
    //loading start
    dispatch(loadingStateChanged({ getAllCompanyRatings: true }));

    const response = await myAxios().get(`api/companyrating/ratings/${companyId}`);

    const data = await response.data;

    //loading end
    dispatch(loadingStateChanged({ getAllCompanyRatings: false }));

    if (data.errors) {
      return errorAction({ getAllCompanyRatings: data.errors });
    }

    dispatch(getAllCompanyRatingsSuccess(data.data));
  };
};

//get all likes
export const getAllCompanyLikesSuccess = (data) => ({
  type: types.COMPANY_GET_ALL_LIKES_SUCCESS,
  payload: data,
});

export const getAllCompanyLikes = (companyId) => {
  return async (dispatch) => {
    try {
      //loading start
      dispatch(loadingStateChanged({ getAllCompanyLikes: true }));

      const response = await myAxios().get(`api/companyrating/likes/${companyId}`);
      const data = await response.data;

      //loading end
      dispatch(loadingStateChanged({ getAllCompanyLikes: false }));

      if (data.errors) {
        return errorAction({ getAllCompanyLikes: data.errors });
      }
      dispatch(getAllCompanyLikesSuccess(data.data));
    } catch (ex) {
      //loading end
      dispatch(loadingStateChanged({ getAllCompanyLikes: false }));
    }
  };
};

//rating to company
export const ratingToCompanySuccess = () => ({
  type: types.RATİNG_TO_COMPANY_SUCCESS,
  payload: [],
});

export const ratingToCompany = (companyId, userId, rating) => {
  return async (dispatch) => {
    //loading start
    dispatch(loadingStateChanged({ companyRating: true }));

    const response = await myAxios().post(
      `api/companyrating/ratings/${companyId}/${userId}/${rating}`,
    );
    var data = await response.data;

    if (data.errors) {
      //loading end
      dispatch(loadingStateChanged({ companyRating: false }));

      return errorAction({ companyRating: data.errors });
    }

    //loading end
    dispatch(loadingStateChanged({ companyRating: 'ok' }));

    dispatch(ratingToCompanySuccess());
  };
};

//like to company
export const likeToCompanySuccess = () => ({
  type: types.LIKE_TO_COMPANY_SUCCESS,
  payload: [],
});

export const likeToCompany = (companyId, userId) => {
  return async (dispatch) => {
    try {
      //loading start
      dispatch(loadingStateChanged({ companyLike: true }));

      const response = await myAxios().post(`api/companyrating/likes/${companyId}/${userId}`);
      var data = await response.data;
      if (data.errors) {
        //loading end
        dispatch(loadingStateChanged({ companyLike: false }));

        return errorAction({ companyLike: data.errors });
      }

      //loading success
      dispatch(loadingStateChanged({ companyLike: 'ok' }));

      dispatch(likeToCompanySuccess());
    } catch (ex) {
      //loading end
      dispatch(loadingStateChanged({ companyLike: false }));
    }
  };
};

//Unrating func
export const unratingToCompanySuccess = () => ({
  type: types.UNRATİNG_TO_COMPANY_SUCCESS,
  payload: [],
});

export const unratingToCompany = (companyId, userId, rating) => {
  return async (dispatch) => {
    //loading start
    dispatch(loadingStateChanged({ companyRating: true }));

    const response = await myAxios().delete(
      `api/companyrating/ratings/${companyId}/${userId}/${rating}`,
    );
    var data = await response.data;

    if (data.errors) {
      //loading end
      dispatch(loadingStateChanged({ companyRating: false }));
      return errorAction({ companyRating: data.errors });
    }

    //loading success
    dispatch(loadingStateChanged({ companyRating: 'ok' }));

    dispatch(unratingToCompanySuccess());
  };
};

//Unlike func
export const unlikeToCompanySuccess = () => ({
  type: types.UNLIKE_TO_COMPANY_SUCCESS,
  payload: [],
});

export const unlikeToCompany = (companyId, userId) => {
  return async (dispatch) => {
    try {
      //loading start
      dispatch(loadingStateChanged({ companyLike: true }));

      const response = await myAxios().delete(`api/companyrating/likes/${companyId}/${userId}`);
      var data = await response.data;
      if (data.errors) {
        //loading start
        dispatch(loadingStateChanged({ companyLike: false }));
        return errorAction({ companyLike: data.errors });
      }

      //loading success
      dispatch(loadingStateChanged({ companyLike: 'ok' }));

      dispatch(unlikeToCompanySuccess());
    } catch (ex) {
      //loading end
      dispatch(loadingStateChanged({ companyLike: false }));
    }
  };
};
