import * as types from '../actionTypes/chatTypes';
import { myAxios } from '../myAxios';
import { loadingStateChanged } from './loadingStateActions';

export const notReadMessageLengthTotalSuccess = (total) => ({
  type: types.NOT_READ_MESSAGE_TOTAL,
  payload: total,
});

export const notReadMessageLengthIncreaseSuccess = () => ({
  type: types.NOT_READ_MESSAGE_INCREASE,
});
export const getAllMyChatsSuccess = (data) => ({
  type: types.GET_ALL_MY_CHAT,
  payload: data,
});

export const getAllMyChats = (userId) => {
  return async (dispatch) => {
    var res = await myAxios().get('/api/chat/my/' + userId);
    var data = await res.data;

    if (data.data) {
      var chats = data.data;
      var notReadMessagesLength = 0;
      chats.forEach((chat) => {
        var leng = chat.messages.filter(
          (x) => x.isRead == false && x.senderUserId != userId,
        ).length;
        notReadMessagesLength += leng;
      });
      dispatch(notReadMessageLengthTotalSuccess(notReadMessagesLength));
    }
    return dispatch(getAllMyChatsSuccess(data.data));
  };
};

export const sendMessageSuccess = (data) => ({
  type: types.SEND_MESSAGE_SUCCESS,
  payload: data,
});

export const sendMessage = (
  messageObj = {
    msg: '',
    chatId: 0,
    senderUserId: 0,
  },
) => {
  return async (dispatch) => {
    var res = await myAxios().post('/api/chat/send/message', messageObj);
    var data = await res.data;
    return dispatch(sendMessageSuccess(data.data));
  };
};

export const messageAddSuccess = (data) => ({
  type: types.MESSAGE_ADD_SUCCESS,
  payload: data,
});

export const messageRead = (userId, chatId) => {
  return async (dispatch) => {
    await myAxios().post(`/api/Chat/readAll/${chatId}/${userId}`);
  };
};

export const notReadEmailSend = (userIds, chat) => {
  return async (dispatch) => {
    var filterMessages = chat.messages.filter((x) => x.isRead == false);
    var senderUserSelectId = filterMessages[filterMessages.length - 1]?.senderUserId;
    if (senderUserSelectId && userIds.length == 2) {
      var senderUserId = userIds.filter((x) => x == senderUserSelectId);
      var userId = userIds.filter((x) => x != senderUserSelectId);
      var chatId = chat.id;
      await myAxios().post(`/api/Chat/notRead/email/send/${senderUserId}/${userId}/${chatId}`);
    }
  };
};

export const messageAdd = (messageObj, userId, myChats = []) => {
  return async (dispatch) => {
    var path = window.location.pathname;
    var isChats = path.includes('/chats/');
    var isMyChat = myChats.filter((x) => x.id == messageObj.chatId)[0];
    if (userId != messageObj.senderUserId && !isChats && isMyChat) {
      dispatch(notReadMessageLengthIncreaseSuccess());
      // Bildirim izni isteme & gönderme
    }

    return dispatch(messageAddSuccess(messageObj));
  };
};

export const createNewChatSuccess = (data) => ({
  type: types.CREATE_NEW_CHAT_SUCCESS,
  payload: data,
});

export const createNewChat = (userIds = []) => {
  return async (dispatch) => {
    dispatch(loadingStateChanged({ createNewChat: true }));

    var res = await myAxios().post('/api/chat/create', userIds);

    dispatch(loadingStateChanged({ createNewChat: false }));
    dispatch(createNewChatSuccess(userIds));
    setTimeout(() => {
      dispatch(createNewChatSuccess(0));
    }, 300);
  };
};

export const createNewChatResetSuccess = () => ({
  type: types.CREATE_NEW_CHAT_RESET,
});

export const createNewChatReset = () => {
  return async (dispatch) => {
    dispatch(createNewChatResetSuccess(0));
  };
};
