import axios from 'axios';
axios.defaults.withCredentials = true; // Enables sending cookies by default

var tokenWrite = () => JSON.parse(localStorage.getItem('token')); // Function to retrieve token
export const myAPIUrl = 'https://api.nakliyecim.net/';
export const myAxios = () => {
  return axios.create({
    baseURL: myAPIUrl,
    headers: {
      'Content-Type': 'application/json', // Sets content type
      Authorization: `Bearer ${tokenWrite()}`, // Sets authorization header with token
    },
    withCredentials: true, // Enables sending cookies for this instance
  });
};
