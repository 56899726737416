import React, { useEffect, useState } from 'react';
import { companyGetAll, companyGetById } from '../../redux/actions/companyActions';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import styles from './CompanyList.module.scss';
import img from '../../images/no.jpg';
import { categoryGetAll } from '../../redux/actions/categoryActions';

import Pagenations from '../pagenations';
import { citiesAndTownsGetAll } from '../../redux/actions/CitiesAndTowns';
import Loading from '../loading/Loading';
import { getCompanyAdsesRandom } from '../../redux/actions/companyAdsActions';
import companyAdsBind from '../../hooks/companyAdsBind';
import {
  getAllCitiesByCountryId,
  getAllCountries,
  getAllTonwsByCityId,
} from '../../redux/actions/geographyActions';
import { addFiltreItem, filterHook, removeFiltreItem } from '../../hooks/filterHooks';

const CompanyList = () => {
  const dispatch = useDispatch();
  const companyList = useSelector((state) => state.companyReducer);
  const categories = useSelector((state) => state.categoriesReducer);
  const geographyData = useSelector((state) => state.geographyReducer);
  const isLoadingState = useSelector((state) => state.loadingStateReducer);
  const [companies, setCompanies] = useState(companyList);
  const [endIndex, setEndIndex] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterObj, setFilterObj] = useState({});
  const [isShowFilter, setIsShowFilter] = useState(false);
  const [geography, setGeography] = useState({
    cities: [],
    towns: [],
    countries: [],
  });

  const actions = {
    getById: bindActionCreators(companyGetById, dispatch),
    getAllCompany: bindActionCreators(companyGetAll, dispatch),
    getAllCategory: bindActionCreators(categoryGetAll, dispatch),
    getAllCities: bindActionCreators(citiesAndTownsGetAll, dispatch),
    getAllCountries: bindActionCreators(getAllCountries, dispatch),
    getAllCitiesByCountryId: bindActionCreators(getAllCitiesByCountryId, dispatch),
    getAllTonwsByCityId: bindActionCreators(getAllTonwsByCityId, dispatch),
  };

  useEffect(() => {
    actions.getAllCompany();
    actions.getAllCategory();
    actions.getAllCities();
    actions.getAllCountries();
  }, []);

  useEffect(() => {
    if (companyList) {
      setCompanies(companyList);
    }
  }, [companyList]);

  useEffect(() => {
    actions.getAllCitiesByCountryId(filterObj.countryId);
    setFilterObj({ ...filterObj, cityId: 0, townId: 0 });
  }, [filterObj.countryId]);

  useEffect(() => {
    actions.getAllTonwsByCityId(filterObj.cityId);
    setFilterObj({ ...filterObj, townId: 0 });
  }, [filterObj.cityId]);

  useEffect(() => {
    if (geographyData) {
      setGeography(geographyData);
    }
  }, [geographyData]);

  useEffect(() => {
    handleFilterSelect();
    setCurrentPage(1);
  }, [filterObj]);

  const handleFilterSelect = () => {
    setCompanies(filterHook(companyList, filterObj));
  };

  return (
    <div className={styles['page']}>
      {isLoadingState.companyList ? (
        <Loading />
      ) : (
        <>
          {/* filtreleme işlemleri */}
          <div className={styles['page__header']}>
            <div className={styles['page__header__filter-box']}>
              <button
                onClick={() => {
                  setIsShowFilter(!isShowFilter);
                }}
                className={styles['page__header__filter-box__btn']}>
                Filtre Uygula
              </button>
              {isShowFilter && (
                <div className={styles['page__header__filter-box__filters']}>
                  <div
                    onClick={() => {
                      setIsShowFilter(false);
                    }}
                    className={styles['page__header__filter-box__filters__closer']}></div>
                  <div className={styles['page__header__filter-box__filters__group']}>
                    <label className={styles['page__header__filter-box__filters__group__label']}>
                      Ülke Seç
                    </label>
                    <select
                      onChange={(e) => {
                        setFilterObj(addFiltreItem(filterObj, [e.target.name, e.target.value]));
                      }}
                      name='countryId'
                      value={filterObj.countryId || 0}
                      className={styles['page__header__filter-box__filters__group__input']}>
                      <option value={0}>Ülke Seç</option>
                      {geography.countries.map((country, i) => (
                        <option key={i} value={country.id}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {geography.cities.length > 0 && (
                    <div className={styles['page__header__filter-box__filters__group']}>
                      <label className={styles['page__header__filter-box__filters__group__label']}>
                        İl Seç
                      </label>
                      <select
                        onChange={(e) => {
                          setFilterObj(addFiltreItem(filterObj, [e.target.name, e.target.value]));
                        }}
                        value={filterObj.cityId || 0}
                        name='cityId'
                        className={styles['page__header__filter-box__filters__group__input']}>
                        <option value={0}>İl Seç</option>
                        {geography.cities.map((city, i) => (
                          <option key={i} value={city.id}>
                            {city.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  {geography.towns.length > 0 && (
                    <div className={styles['page__header__filter-box__filters__group']}>
                      <label className={styles['page__header__filter-box__filters__group__label']}>
                        İlçe Seç
                      </label>
                      <select
                        onChange={(e) => {
                          setFilterObj(addFiltreItem(filterObj, [e.target.name, e.target.value]));
                        }}
                        value={filterObj.townId}
                        name='townId'
                        className={styles['page__header__filter-box__filters__group__input']}>
                        <option value={0}>İlçe seç</option>
                        {geography.towns.map((town, i) => (
                          <option key={i} value={town.id}>
                            {town.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}

                  <div className={styles['page__header__filter-box__filters__group']}>
                    <label className={styles['page__header__filter-box__filters__group__label']}>
                      Firma Tipi Seç
                    </label>
                    <select
                      onChange={(e) => {
                        setFilterObj(addFiltreItem(filterObj, [e.target.name, e.target.value]));
                      }}
                      name='categoryId'
                      className={styles['page__header__filter-box__filters__group__input']}>
                      <option value={0}>Firma Tipi</option>
                      {categories?.map((category, i) => (
                        <option key={i} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
            </div>
          </div>
          {companies?.length === 0 && (
            <h1 className={styles['page__empty-text']}>
              Arama sonucunda nakliye firması bulunamadı
            </h1>
          )}
          <ul className={styles['page__company-list']}>
            {/* companies */}
            {companies?.slice(startIndex, endIndex).map((company, i) => {
              return (
                company?.id && (
                  <Link key={i} to={'/details/company/' + company.id}>
                    <li data-isAds={company.isAds} className={styles['page__company-list__item']}>
                      <img
                        src={company.image || img}
                        className={styles['page__company-list__item__image']}
                        alt={company?.name}
                      />
                      <div className={styles['page__company-list__item__company']}>
                        <h3 className={styles['page__company-list__item__company__name']}>
                          {company?.name}
                        </h3>
                        <span className={styles['page__company-list__item__company__city']}>
                          <i className='fas fa-location-dot'></i> {company.country + ' /'}{' '}
                          {company.city} {company.town && '/' + company.town}
                        </span>
                        <span className={styles['page__company-list__item__company__phones']}>
                          <span
                            className={styles['page__company-list__item__company__phones__phone']}>
                            <i className='fas fa-phone'></i> {company.phone}
                          </span>
                        </span>
                      </div>
                    </li>
                  </Link>
                )
              );
            })}
          </ul>

          <Pagenations
            pageArray={companies}
            setEnd={setEndIndex}
            setStart={setStartIndex}
            showElementLength={50}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </>
      )}
    </div>
  );
};

export default CompanyList;
