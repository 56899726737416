import React from 'react';

import styles from './Kvkk.module.scss';

const Kvkk = () => {
  return (
    <div className={styles['kvkk']}>
      <h2>Veri Sorumlusu</h2>
      <p>
        Nakliyecim.net olarak, kişisel verilerinizi toplarken ve işlerken veri sorumlusu sıfatını
        taşıyoruz. Kişisel verilerinizi koruma konusunda taahhütte bulunuyor ve KVKK'ya uygun olarak
        hareket ediyoruz.
      </p>

      <h2>Toplanan Kişisel Veriler</h2>
      <p>
        Nakliyecim.net üzerindeki ilanları görüntülemek, ilan vermek veya hesap oluşturmak için ad,
        soyad, adres, telefon numarası, e-posta, kullanıcı adı ve şifre gibi kişisel verilerinizi
        topluyoruz. Ayrıca, sitemizi ziyaret ettiğinizde otomatik olarak IP adresi, tarayıcı
        bilgisi, coğrafi konum bilgisi gibi teknik veriler de toplanmaktadır.
      </p>

      <h2>Veri İşleme Amaçları ve Hukuki Sebepler</h2>
      <p>
        Kişisel verilerinizi, ilanların yönetimi, iletişim, hesap oluşturma ve güvenlik amacıyla
        işliyoruz. Bu işlemler, sözleşmenin ifası, yasal yükümlülüklerin yerine getirilmesi ve meşru
        menfaatlerimizin korunması gibi hukuki sebeplere dayanmaktadır.
      </p>

      <h2>Veri Saklama Süreleri</h2>
      <p>
        Kişisel verilerinizi, hesabınız aktif olduğu sürece saklıyoruz. Hesabınızı silmeniz
        durumunda ise verileriniz silinecektir. Ayrıca, yasal saklama süreleri ve meşru
        menfaatlerimizin korunması gerekliliği doğrultusunda verilerinizi belirli bir süre
        saklayabiliriz.
      </p>

      <h2>Veri Güvenliği</h2>
      <p>
        Kişisel verilerinizi korumak için gerekli teknik ve idari güvenlik önlemlerini alıyoruz.
        Veri sızıntılarına, kayıplara ve kötüye kullanıma karşı önlemlerimizi düzenli olarak gözden
        geçiriyor ve güncelliyoruz.
      </p>

      <h2>Veri Sahibi Hakları</h2>
      <p>
        KVKK kapsamında, kişisel verilerinize ilişkin olarak bilgi alma, veri düzeltme, silme,
        işleme faaliyetlerine itiraz etme gibi haklara sahipsiniz. Bu haklarınızı kullanmak için
        bize ulaşabilirsiniz.
      </p>

      <h2>İletişim Bilgileri</h2>
      <p>
        KVKK Metni ile ilgili her türlü soru, görüş ve talepleriniz için bize{' '}
        <a href='mailto:info@nakliyecim.net'>info@nakliyecim.net</a> adresinden ulaşabilirsiniz.
      </p>
    </div>
  );
};

export default Kvkk;
