import { myAxios } from '../myAxios';
import * as types from '../actionTypes/ForgotPasswordTypes';
import { errorAction } from './errorActions';

export const ForgotPasswordSendEmailSuccess = (data) => ({
  type: types.FORGOT_PASSWORD_SUCCESS,
  payload: data,
});

export const ForgotPasswordSendEmail = (email = '') => {
  return async (dispatch) => {
    dispatch(ForgotPasswordSendEmailSuccess({ sendEmail: false }));
    dispatch(errorAction({ forgotPassword: '' }));

    var res = await myAxios().post('/api/user/forgot-password/', {
      email,
    });
    var data = await res.data;
    if (data.errors) {
      return dispatch(errorAction({ forgotPassword: data.errors }));
    }

    dispatch(ForgotPasswordSendEmailSuccess({ sendEmail: true }));
  };
};

export const ForgotPasswordSendEmailAcceptSuccess = (data) => ({
  type: types.FORGOT_PASSWORD_ACCESS_SUCCESS,
  payload: data,
});

export const ForgotPasswordSendEmailAccept = (
  obj = {
    code: '',
    password: '',
    rePassword: '',
  },
) => {
  return async (dispatch) => {
    dispatch(errorAction({ forgotPasswordAccept: '' }));
    dispatch(ForgotPasswordSendEmailAcceptSuccess({ emailAccept: false }));
    var res = await myAxios().post('/api/user/forgot-password-accept/', obj);
    var data = await res.data;

    if (data.errors) {
      return dispatch(errorAction({ forgotPasswordAccept: data.errors }));
    }
    dispatch(ForgotPasswordSendEmailAcceptSuccess({ emailAccept: true }));
  };
};
